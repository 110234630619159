import ethereumLogoUrl from 'assets/svg/ethereum.svg'
import polygonLogoUrl from 'assets/svg/polygon-logo.svg'
import ms from 'ms.macro'

import { SupportedChainId, SupportedL1ChainId, SupportedL2ChainId } from './chains'

interface L1ChainInfo {
  readonly blockWaitMsBeforeWarning?: number
  readonly chainId: number
  readonly docs: string
  readonly explorer: string
  readonly infoLink: string
  readonly label: string
  readonly colorLabel: string
  readonly logoUrl: string
  readonly bridge?: string
  readonly nativeCurrency: {
    name: string // 'Goerli ETH',
    symbol: string // 'gorETH',
    decimals: number //18,
  }
}

//export interface L1ChainInfo {
//  readonly blockWaitMsBeforeWarning?: number;
//  readonly chainId: number;
//  readonly docs: string;
//  readonly explorer: string;
//  readonly infoLink: string;
//  readonly label: string;
//  readonly logoUrl: string;
//  readonly bridge?: string;
//  readonly nativeCurrency: {
//    name: string; // 'Goerli ETH',
//    symbol: string; // 'gorETH',
//    decimals: number; //18,
//  };
//}

export interface L2ChainInfo extends L1ChainInfo {
  readonly bridge: string
  readonly statusPage?: string
}

type ChainInfo = {
  readonly [chainId: number]: L1ChainInfo | L2ChainInfo
} & {
  readonly [chainId in SupportedL2ChainId]: L2ChainInfo
} & {
  readonly [chainId in SupportedL1ChainId]: L1ChainInfo
}

export const CHAIN_INFO: ChainInfo = {
  [SupportedChainId.ETHEREUM]: {
    chainId: SupportedChainId.ETHEREUM,
    docs: 'https://docs.ethereum.org/',
    explorer: 'https://etherscan.io/',
    infoLink: 'https://ethereum.org/#/',
    label: 'Ethereum',
    colorLabel: '#8C8C8C',
    logoUrl: ethereumLogoUrl,
    nativeCurrency: { name: 'Ethereum', symbol: 'ETH', decimals: 18 },
  },
  [SupportedChainId.POLYGON]: {
    chainId: SupportedChainId.POLYGON,
    blockWaitMsBeforeWarning: ms`10m`,
    bridge: 'https://bridge.polygon.technology',
    docs: 'https://docs.polygon.technology/',
    explorer: 'https://polygonscan.com/',
    infoLink: 'https://polygon.technology/#/',
    label: 'Polygon',
    colorLabel: '#8247E5',
    logoUrl: polygonLogoUrl,
    nativeCurrency: { name: 'Polygon', symbol: 'MATIC', decimals: 18 },
  },
  [SupportedChainId.POLYGON_MUMBAI]: {
    blockWaitMsBeforeWarning: ms`10m`,
    bridge: 'https://bridge.polygon.technology',
    chainId: SupportedChainId.POLYGON_MUMBAI,
    docs: 'https://docs.polygon.technology/',
    explorer: 'https://mumbai.polygonscan.com/',
    infoLink: 'https://polygon.technology/#/',
    label: 'Polygon Testnet',
    colorLabel: '#8247E5',
    logoUrl: polygonLogoUrl,
    nativeCurrency: { name: 'Polygon Mumbai', symbol: 'MATIC', decimals: 18 },
  },
}

export function getChainInfo(chainId?: number): L1ChainInfo | L2ChainInfo | undefined {
  if (!chainId || !SupportedChainId[chainId]) return undefined
  return CHAIN_INFO[chainId]
}
