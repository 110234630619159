import 'inter-ui'
import '@reach/dialog/styles.css'
import 'polyfills'

//import { BlockNumberProvider } from "hooks/useBlockNumber";
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter, HashRouter } from 'react-router-dom'
import { isBrowserRouterEnabled } from 'utils/env'
import { unregister as unregisterServiceWorker } from 'utils/serviceWorker'

//import Web3Provider from './components/Web3Provider'
//import { LanguageProvider } from './i18n'
import App from './pages/App'
//import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import store from './state'
import ApplicationUpdater from './state/application/updater'
//import ListsUpdater from "./state/lists/updater";
//import LogsUpdater from "./state/logs/updater";
import UserUpdater from './state/user/updater'
import ThemeProvider, { ThemedGlobalStyle } from './theme'
//import RadialGradientByChainUpdater from "./theme/RadialGradientByChainUpdater";

if (window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false
}

function Updaters() {
  return (
    <>
      <UserUpdater />
      <ApplicationUpdater />
    </>
  )
}

const container = document.getElementById('root') as HTMLElement
const Router = isBrowserRouterEnabled() ? BrowserRouter : HashRouter

createRoot(container).render(
  <StrictMode>
    <Provider store={store}>
      <HashRouter>
        <Updaters />
        <ThemeProvider>
          <ThemedGlobalStyle />
          <App />
        </ThemeProvider>
      </HashRouter>
    </Provider>
  </StrictMode>
)

if (process.env.REACT_APP_SERVICE_WORKER !== 'false') {
  unregisterServiceWorker() //serviceWorkerRegistration.register()
}
