//import { t } from '@lingui/macro'
//import { Trans } from '@lingui/macro'
import { OutlineCard } from 'components/Card'
import { SOCIAL_INFO } from 'constants/socialInfos'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import React, { useContext, useRef } from 'react'
import { Send } from 'react-feather'
import { Link, NavLink } from 'react-router-dom'
import { useDarkModeManager } from 'state/user/hooks'
import styled, { ThemeContext } from 'styled-components/macro'

import { ReactComponent as MenuIcon } from '../../assets/images/menu.svg'
import { ReactComponent as Twitter } from '../../assets/svg/xIcon.svg'
import { ApplicationModal } from '../../state/application/actions'
import { useModalOpen, useToggleModal } from '../../state/application/hooks'
import { ExternalLink } from '../../theme/components'

const StyledCard = styled(OutlineCard)`
  padding: 8px;
  background-color: ${({ theme }) => theme.primary1}A1;
  border: 1px solid ${({ theme }) => theme.bg3};
`

const StyledMenuIcon = styled(MenuIcon)<{ open?: boolean }>`
  rotate: ${({ open }) => (open ? '45deg' : '0deg')};
  transition: rotate 500ms;
  path {
    stroke: ${({ open, theme }) => (open ? theme.red : theme.white)};
    //stroke: ${({ theme }) => theme.text1};
    //opacity: 0.6;
  }
`

const StyledMenuIcon2 = styled(MenuIcon)<{ open?: boolean }>`
  rotate: ${({ open }) => (open ? '-45deg' : '0deg')};
  transition: rotate 500ms;
  path {
    stroke: ${({ open, theme }) => (open ? theme.red : theme.white)};
    //opacity: 0.6;
  }
`

const StyledMenuButton = styled.button`
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  //height: 40px;
  width: 40px;
  background-color: Transparent;

  padding: 0.15rem 0.5rem;
  border-radius: 12px;

  :hover {
    cursor: pointer;
    outline: none;
    opacity: 0.6;
  }
  transition: opacity 300ms;

  svg {
    margin-top: 2px;
  }
`

const StyledMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  //text-align: center;
`

const MenuFlyout = styled.span`
  min-width: 192px;
  max-height: 480px;
  overflow: auto;
  background-color: ${({ theme }) => theme.primary2}F1;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border: 1px solid ${({ theme }) => theme.bg3};
  border-radius: 16px;
  padding: 8px;
  display: flex;
  right: 0;
  margin-right: -8px;
  flex-direction: column;
  position: absolute;
  top: 44px;
  z-index: 100;
`

const MenuItem = styled(ExternalLink)`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  border-radius: 16px;
  padding: 0.5rem 0.5rem;
  justify-content: space-between;
  color: ${({ theme }) => theme.white};
  :hover {
    cursor: pointer;
    text-decoration: none;
  }
`

const InternalMenuItem = styled(Link)`
  flex: 1;
  padding: 0.5rem 0.5rem;
  color: ${({ theme }) => theme.text2};
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }
  > svg {
    margin-right: 8px;
  }
`

const InternalLinkMenuItem = styled(InternalMenuItem)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0.5rem;
  justify-content: space-between;
  text-decoration: none;
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }
`

const ToggleMenuItem = styled.button<{ isOpen?: boolean }>`
  background-color: transparent;
  border: none;
  display: flex;
  width: 100%;
  padding: 0.5rem 0.5rem;
  justify-content: space-between;
  font-size: 1.25rem;
  font-weight: 500;
  color: ${({ isOpen, theme }) => (isOpen ? theme.text2 : theme.text1)};
  :hover {
    color: ${({ isOpen, theme }) => (isOpen ? theme.text1 : theme.text2)};
    cursor: pointer;
    text-decoration: none;
  }
`

const OverlapIcon = styled.div`
  position: absolute;
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  text-align: center;
  align-items: center;
  padding: 0.5rem 0.5rem;
  font-size: 1.2rem;
  font-weight: 500;
  //justify-content: space-between;
  color: ${({ theme }) => theme.text2};
  text-decoration: none;
  :hover,
  :focus {
    color: ${({ theme }) => theme.text3};
    cursor: pointer;
  }
  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.white};
    background-color: ${({ theme }) => theme.primary1};
  }
`

export default function Menu({ menuId }: { menuId: string }) {
  const theme = useContext(ThemeContext)
  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.MENU)
  const toggle = useToggleModal(ApplicationModal.MENU)
  useOnClickOutside(node, open ? toggle : undefined)

  const [darkMode, toggleDarkMode] = useDarkModeManager()
  const twitterInfo = SOCIAL_INFO['Twitter']
  const telegramInfo = SOCIAL_INFO['Telegram']
  const tiktokInfo = SOCIAL_INFO['Tiktok']

  //const [menu, setMenu] = useState("main");

  //useEffect(() => {
  //  setMenu(menuId);
  //}, [menuId, open]);

  return (
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
    <StyledMenu ref={node as any}>
      <StyledMenuButton onClick={toggle} aria-label="Menu">
        <OverlapIcon>
          <StyledMenuIcon2 open={open} />
        </OverlapIcon>
        <StyledMenuIcon open={open} />
      </StyledMenuButton>

      {open &&
        (() => {
          switch (menuId) {
            default:
              return (
                <MenuFlyout>
                  <StyledCard>
                    <MenuItem href={twitterInfo.infoLink}>
                      <div>{twitterInfo.label + ' (Twitter)'}</div>
                      <Twitter opacity={0.8} width={28} height={28} stroke={theme.white} strokeWidth={20} />
                    </MenuItem>
                    <MenuItem href={telegramInfo.infoLink}>
                      <div>{telegramInfo.label}</div>
                      <Send opacity={0.8} size={28} />
                    </MenuItem>
                  </StyledCard>
                </MenuFlyout>
              )
          }
        })()}
    </StyledMenu>
  )
}
