import { OutlineCard } from 'components/Card'
import { Info } from 'react-feather'
import styled, { keyframes } from 'styled-components/macro'

import DoveGIF from '../../assets/images/bodySA.gif'
import DoveIMG from '../../assets/images/dovePsmile.png'
import { ReactComponent as ETHLogo } from '../../assets/svg/ethereum.svg'
import { AutoColumn } from '../../components/Column'
import PageHeader from '../../components/home/PageHeader'
import { RowFixed } from '../../components/Row'
import SocialPanel from '../../components/SocialPanel'
import { TYPE } from '../../theme'
import AppBody from '../AppBody'
import CategoryItem from '../CategoryItem'

const StyledCard = styled(OutlineCard)`
  padding: 8px 8px 8px 8px;
  align-text: center;
  border: 2px solid ${({ theme }) => theme.primary2};
`

const StyledInfo = styled(Info)`
  height: 16px;
  width: 16px;
  margin-left: 4px;
  color: ${({ theme }) => theme.text3};
  :hover {
    color: ${({ theme }) => theme.text1};
  }
`

const DoveGif = styled.img`
  z-index: 8;
  width: 192px;
  margin-right: 4px;
  margin-bottom: -80px;
`

const DoveAni = keyframes`
    0% {
		margin-bottom: -128px;
    }
    70% {
		margin-bottom: -124px;
    }
	100% {
		margin-bottom: -128px;
    }
  `

const DoveAni2 = keyframes`
    0% {
		margin-bottom: -124px;
    }
    30% {
		margin-bottom: -120px;
    }
	100% {
		margin-bottom: -124px;
    }
  `

const ImageWrapper = styled.div`
  z-index: 9;
  animation: ${DoveAni} 500ms linear infinite;
`

const DoveImg = styled.img`
  z-index: 9;
  width: 96px;
  margin-left: 0px;
  margin-bottom: -124px;
  animation: ${DoveAni} 500ms linear infinite;
`

const DoveImg2 = styled(DoveImg)`
  z-index: 9;
  scale: -1 1;
  margin-left: 56px;
  animation: ${DoveAni2} 500ms linear infinite;
`

export default function Home({ className }: { className?: string }) {
  return (
    <>
      <AppBody>
        <PageHeader />
        <AutoColumn gap="md" style={{ width: '100%' }}>
          <CategoryItem>
            <AutoColumn gap="lg" justify="center" style={{ padding: '16px 8px 0 8px' }}>
              <TYPE.Main textAlign="center">
                Everyone knows them and almost everyone hates them. The dove stands for peace and love but is probably
                the most undervalued animal in the world.
              </TYPE.Main>
              <TYPE.Main textAlign="center">{'To change that -> Join the dove army!'}</TYPE.Main>
              <SocialPanel />
            </AutoColumn>
          </CategoryItem>
          <CategoryItem>
            <AutoColumn gap="lg" justify="center">
              <RowFixed style={{}}>
                <DoveImg src={DoveIMG} />
                <DoveImg2 src={DoveIMG} />
              </RowFixed>
              <DoveGif src={DoveGIF} />
              <ETHLogo width={192} />
              <TYPE.Main fontSize="28px" textAlign="center">
                SOON ON ETHEREUM!
              </TYPE.Main>
            </AutoColumn>
          </CategoryItem>
        </AutoColumn>
      </AppBody>
    </>
  )
}
