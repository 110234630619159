import { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components/macro'

import { SOCIAL_INFO } from '../../constants/socialInfos'
import { ExternalLink } from '../../theme/components'
import { RowBetween } from '../Row'

const SocialRow = styled(RowBetween)`
  padding: 0 64px 0 64px;
  max-width: 512px;
`

const LinkSelector = styled(ExternalLink)`
  align-items: center;
  //background-color: ${({ theme }) => theme.text1};
  //border-radius: 9999px;
  cursor: pointer;
  display: flex;
  width: 64px;
  height 64px;
  //margin-left: 1px;
  //margin-right: 5px;
  //width: 22%;
`

const Logo = styled.img`
  pointer-events: none;
  background-color: white;
  border: 2px solid white;
  border-radius: 9999px;
  height: 60px;
  width: 60px;
`

export default function SocialPannel({ wrapped }: { wrapped?: boolean }) {
  const theme = useContext(ThemeContext)

  const twitterInfo = SOCIAL_INFO['Twitter']
  const telegramInfo = SOCIAL_INFO['Telegram']

  return (
    <SocialRow>
      <LinkSelector title={twitterInfo.label} href={twitterInfo.infoLink}>
        <Logo src={twitterInfo.logoUrl} alt={twitterInfo.label} />
      </LinkSelector>
      <LinkSelector title={telegramInfo.label} href={telegramInfo.infoLink}>
        <Logo src={telegramInfo.logoUrl} alt={telegramInfo.label} />
      </LinkSelector>
    </SocialRow>
  )
}
