import Column from 'components/Column'
import { RowFixed } from 'components/Row'
import React from 'react'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components/macro'

const CategoryBody = styled(Column)<{ backgroundColor?: string }>`
  padding: 1.25rem 1.25rem 1.25rem 1.25rem;
  position: relative;
  gap: 8px;
  background-color: ${({ theme, backgroundColor }) => (backgroundColor ? backgroundColor : theme.primary2)};
  border-radius: 24px;
`

const CategoryWrapper = styled(Column)<{ animate?: boolean; opacity?: number; maxWidth?: string }>`
  margin-top: 1rem;
  max-width: ${({ maxWidth }) => maxWidth ?? '984px'};
  font-size: 20px;
  opacity: ${({ opacity }) => (opacity ? 1 : 0)};
  scale: 1;
  transition: opacity 1500ms;
  border-radius: 24px;
  padding: 0 16px 0 16px;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 0 0 0 0;
	font-size: 16px;
  `};
`

const ContainerWrapper = styled(RowFixed)`
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  height: 160px;
`

const Container = styled.div<{ backgroundColor?: string }>`
  position: absolute;
  width: 440px;
  height: 160px;
`

const ContentWrapper = styled(RowFixed)`
  width: 360px;
  align-items: center;
  height: 80px;
  justify-content: center;
`

const StyledWrapper = styled.div`
  width: 80px;
  border-radius: 9999px;
  background-color: ${({ theme }) => theme.bg1};
  height: 80px;
  border: 2px solid ${({ theme }) => theme.bg3};
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function CategoryItem({ children, opacity, ...rest }: { children: React.ReactNode; opacity?: number }) {
  const { ref, inView } = useInView({ triggerOnce: true })
  return (
    <CategoryWrapper ref={ref} opacity={opacity ?? inView ? 1 : 0} {...rest}>
      {children}
    </CategoryWrapper>
  )
}
