import { t } from '@lingui/macro'
//import { useAtom } from 'jotai'
import { ReactNode, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { SpinnerSVG } from 'theme/components'
import { isBrowserRouterEnabled } from 'utils/env'

import Home from './Home'
import { RedirectPathToHomeOnly } from './Home/redirects'

// this is the same svg defined in assets/images/blue-loader.svg
// it is defined here because the remote asset may not have had time to load when this file is executing
const LazyLoadSpinner = () => (
  <SpinnerSVG width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M92 47C92 22.1472 71.8528 2 47 2C22.1472 2 2 22.1472 2 47C2 71.8528 22.1472 92 47 92"
      stroke="#2172E5"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SpinnerSVG>
)

interface RouterConfig {
  browserRouterEnabled?: boolean
  hash?: string
}

/**
 * Convenience hook which organizes the router configuration into a single object.
 */
export function useRouterConfig(): RouterConfig {
  const browserRouterEnabled = isBrowserRouterEnabled()
  const { hash } = useLocation()
  return useMemo(
    () => ({
      browserRouterEnabled,
      hash,
    }),
    [browserRouterEnabled, hash]
  )
}

export interface RouteDefinition {
  path: string
  nestedPaths: string[]
  staticTitle: string
  enabled: (args: RouterConfig) => boolean
  getElement: (args: RouterConfig) => ReactNode
}

// Assigns the defaults to the route definition.
function createRouteDefinition(route: Partial<RouteDefinition>): RouteDefinition {
  return {
    getElement: () => null,
    staticTitle: 'Trade Dove Homepage',
    enabled: () => true,
    path: '/',
    nestedPaths: [],
    // overwrite the defaults
    ...route,
  }
}

export const routes: RouteDefinition[] = [
  createRouteDefinition({
    path: '/',
    getElement: () => <RedirectPathToHomeOnly />,
  }),
  createRouteDefinition({
    path: '/home',
    getElement: () => <Home />,
    staticTitle: t`Show Information about Trade Dove`,
  }),
  createRouteDefinition({ path: '*', getElement: () => <RedirectPathToHomeOnly /> }),
]
