import etherscanLogoUrl from 'assets/svg/etherscan.svg'
import telegramLogoUrl from 'assets/svg/telegram.svg'
import tiktokLogoUrl from 'assets/svg/tiktokLogo.svg'
import xLogoUrl from 'assets/svg/x.svg'

import { SupportedChainId } from './chains'

interface LinkInfo {
  readonly infoLink: string
  readonly label: string
  readonly colorLabel?: string
  readonly logoUrl: string
}

type SocialInfo = {
  readonly [label: string]: LinkInfo
}

type ExplorerInfo = {
  readonly [chainId: number]: LinkInfo
}

export const SOCIAL_INFO: SocialInfo = {
  Tiktok: {
    infoLink: 'https://tiktok.com/@TradeDove',
    label: 'TikTok',
    logoUrl: tiktokLogoUrl,
  },
  Telegram: {
    infoLink: 'https://t.me/TradeDoveMEME',
    label: 'Telegram',
    logoUrl: telegramLogoUrl,
  },
  Twitter: {
    infoLink: 'https://x.com/TradeDove',
    label: 'X',
    logoUrl: xLogoUrl,
  },
}

export const EXPLORER_TOKEN_INFO: ExplorerInfo = {
  [SupportedChainId.ETHEREUM]: {
    infoLink: 'https://etherscan.io/address/0x0B5Afdc93A86062A49063EA96AE944D7F966B6AE',
    label: 'EtherScan',
    logoUrl: etherscanLogoUrl,
  },
}
