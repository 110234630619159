export enum SupportedChainId {
  ETHEREUM = 1,
  POLYGON = 137,
  POLYGON_MUMBAI = 80001,
}

enum SupportedTestnetId {
  POLYGON_TESTNET = SupportedChainId.POLYGON_MUMBAI,
}

const ALL_SUPPORTED_CHAIN_IDS: SupportedChainId[] = [
  SupportedChainId.ETHEREUM,
  SupportedChainId.POLYGON,
  SupportedChainId.POLYGON_MUMBAI,
]

export const L1_CHAIN_IDS = [SupportedChainId.ETHEREUM] as const

const L2_CHAIN_IDS = [SupportedChainId.POLYGON, SupportedChainId.POLYGON_MUMBAI] as const

export type SupportedL2ChainId = (typeof L2_CHAIN_IDS)[number]

export type SupportedL1ChainId = (typeof L1_CHAIN_IDS)[number]

function isSupportedChain(chainId: number | null | undefined): chainId is SupportedChainId {
  //return !!chainId && !!SupportedChainId[chainId];
  return !!chainId && !!SupportedChainId[chainId]
}

const POLYGON_HELP_CENTER_LINK = 'https://docs.polygon.technology'
