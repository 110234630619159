import { Buffer } from 'buffer'
import { useContext } from 'react'
import { useInView } from 'react-intersection-observer'
import styled, { css, keyframes, ThemeContext } from 'styled-components/macro'

import { useIsMobile } from '../../hooks/useIsMobile'
import { useIsDarkMode } from '../../state/user/hooks'
import Canvas from '../Canvas'
import { AutoColumn } from '../Column'
import { DoveTypes, DoveWink, MoonImage, SunImage } from '../Loader'
import Row, { RowBetween } from '../Row'

const HeaderWrapper = styled.main`
  width: 100%;
  //display: flex;
  margin-bottom: 70%;
  align-items: center;
`

const SubRow = styled(Row)`
  gap: 6px;
`

const Bunner = styled.img`
  width: 100%;
`

const DowContainer = styled.div`
  width: 100%;
`

const Container = styled.div`
  justify-content: center;
  position: absolute;
  margin-top: 24%;
  width: 100%;
  overflow: hidden;
`

const DoveRow = styled(RowBetween)`
  width: 100%;
`

const Line = styled.div<{ height?: string }>`
  width: 100%;
  position: relative;
  z-index: -1;
  margin-bottom: 16px;
  height: ${({ height }) => (height ? height : '5px')};
  background: #222222;
`

const SVGImage = styled.img`
  width: 100%;
  position: relative;
  pointer-events: none;
`

const Canv = styled.div`
  width: 100%;
  top: 10%;
  height: 0;
  position: relative;
`

const Canv2 = styled.div`
  width: 100%;
  height: 0;
  position: relative;
`

const Svg = styled.div`
  width: 20%;
  position: relative;
`

const move = keyframes`
    5% {
		left: 105%;
    }
	100% {
		left: 105%;
    }
  `

const Ball = styled.div<{ height?: string; delay?: string; animate?: boolean }>`
  position: relative;
  top: -24px;
  left: -16px;
  z-index: -1;
  width: ${({ height }) => (height ? height : '6px')};
  height: ${({ height }) => (height ? height : '6px')};
  border-radius: 9999px;
  background: #9999ff;
  box-shadow: 0px 0px 4px 4px #9999ff99;
  ${({ animate }) =>
    animate
      ? css`
          animation: ${move} 10s linear infinite;
        `
      : null}
  animation-delay: ${({ delay }) => (delay ? delay : '2s')};
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function PageHeader() {
  const { ref, inView } = useInView()
  const theme = useContext(ThemeContext)
  const darkmode = useIsDarkMode()
  //const dpi = window.devicePixelRatio || 1;
  const data = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 768 224" width="100%">
	<g transform="translate(-308)">
      ${DoveTypes(3, 905, false)}
	</g>
	<g transform="translate(-100)">
      ${DoveTypes(4, 4998, false)}
	</g>
	<g transform="translate(100)">
      ${DoveTypes(2, 0, false)}
	</g>
	<g transform="translate(308)">
      ${DoveTypes(1, 11111, false)}
	</g>
	</svg>
  `

  const data2 = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 968 224" width="100%">
	<g transform="scale(0.8)">
	<g transform="translate(-396)">
      ${DoveTypes(2, 4444, false)}
	</g>
	<g transform="translate(-224)">
      ${DoveTypes(6, 44444, false)}
	</g>
	<g transform="translate(-72)">
      ${DoveTypes(2, 23767, false)}
	</g>
	<g transform="translate(122)">
      ${DoveTypes(6, 11451, false)}
	</g>
	<g transform="translate(320)">
      ${DoveTypes(4, 7777, false)}
	</g>
	<g transform="translate(466)">
      ${DoveTypes(6, 12121, false)}
	</g>
	<g transform="translate(640)">
      ${DoveTypes(4, 28765, false)}
	</g>
	</g>
	</svg>
  `

  const data3 = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 868 224" width="100%">
	<g transform="scale(0.9)">
	<g transform="translate(-354)">
      ${DoveTypes(0, 6000, false)}
	</g>
	<g transform="translate(-192)">
      ${DoveTypes(6, 24554, false)}
	</g>
	<g transform="translate(-36)">
      ${DoveTypes(1, 32565, false)}
	</g>
	<g transform="translate(132)">
      ${DoveTypes(4, 41020, false)}
	</g>
	<g transform="translate(290)">
      ${DoveTypes(0, 9644, false)}
	</g>
	<g transform="translate(450)">
      ${DoveTypes(5, 36003, false)}
	</g>
	</g>
	</svg>
  `

  const data4 = darkmode ? MoonImage('5s', theme.primary3) : SunImage('5s', theme.primary3)

  const dataw = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 768 224" width="100%">
	<g transform="translate(-308)">
      ${DoveWink({ type: 3, id: 905, delay: '23s' })}
	</g>
	<g transform="translate(-100)">
      ${DoveWink({ type: 4, id: 4998, delay: '9s' })}
	</g>
	<g transform="translate(100)">
      ${DoveWink({ type: 2, id: 0, delay: '0s' })}
	</g>
	<g transform="translate(308)">
      ${DoveWink({ type: 1, id: 11111, delay: '16s' })}
	</g>
	</svg>
	`

  const dataw2 = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 968 224" width="100%">
	<g transform="scale(0.8)">
	<g transform="translate(-396)">
      ${DoveWink({ type: 2, id: 4444, delay: '15s' })}
	</g>
	<g transform="translate(-224)">
      ${DoveWink({ type: 6, id: 44444, delay: '1s' })}
	</g>
	<g transform="translate(-72)">
      ${DoveWink({ type: 2, id: 23767, delay: '10s' })}
	</g>
	<g transform="translate(122)">
      ${DoveWink({ type: 6, id: 11451, delay: '25s' })}
	</g>
	<g transform="translate(320)">
      ${DoveWink({ type: 4, id: 7777, delay: '13s' })}
	</g>
	<g transform="translate(466)">
      ${DoveWink({ type: 6, id: 12121, delay: '7s' })}
	</g>
	<g transform="translate(640)">
      ${DoveWink({ type: 4, id: 28765, delay: '29s' })}
	</g>
	</g>
	</svg>
  `

  const dataw3 = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 868 224" width="100%">
	<g transform="scale(0.9)">
	<g transform="translate(-354)">
      ${DoveWink({ type: 0, id: 6000, delay: '12s' })}
	</g>
	<g transform="translate(-192)">
      ${DoveWink({ type: 6, id: 24554, delay: '21s' })}
	</g>
	<g transform="translate(-36)">
      ${DoveWink({ type: 1, id: 32565, delay: '4s' })}
	</g>
	<g transform="translate(132)">
      ${DoveWink({ type: 4, id: 41020, delay: '18s' })}
	</g>
	<g transform="translate(290)">
      ${DoveWink({ type: 0, id: 9644, delay: '27s' })}
	</g>
	<g transform="translate(450)">
      ${DoveWink({ type: 5, id: 36003, delay: '18s' })}
	</g>
	</g>
	</svg>
  `

  const dataw4 = darkmode ? DoveWink({ type: 0, id: 179, delay: '5s' }) : DoveWink({ type: 0, id: 28003, delay: '5s' })

  const datawb = new Buffer(dataw)
  const dataw2b = new Buffer(dataw2)
  const dataw3b = new Buffer(dataw3)
  const dataw4b = new Buffer(data4)

  const dpi = window.devicePixelRatio || 1
  const isMobile = useIsMobile()

  //<HeaderRow data={data3} dataw={dataw3} width={868} />
  //	<Line height="4px" style={{ bottom: '4px' }} />
  //      <Ball animate={inView} delay="5s" />
  //	<HeaderRow data={data2} dataw={dataw2} width={968} />
  //	<div style={{ marginTop: '-23%', marginBottom: '-14.8%' }}>
  //	    <Line height="3px" style={{ bottom: '4px' }} />
  //          <Ball animate={inView} delay="10s" />
  //    </div>
  //	<HeaderRow data={data} dataw={dataw} width={768} />
  //	<div style={{ marginTop: '-23%', marginBottom: '-14.8%' }}>
  //	    <Line style={{ bottom: '4px' }} />
  //          <Ball animate={inView} />
  //	</div>

  return (
    <HeaderWrapper>
      <AutoColumn gap="md" justify="center" style={{ display: 'flex' }}>
        <div style={{ width: '100%' }}>
          <Canv style={{ marginTop: '-1%', marginLeft: '20%' }}>
            <SVGImage
              src={`data:image/svg+xml;base64,${dataw4b.toString('base64')}`}
              width={isMobile ? 300 : 100}
              height={isMobile ? 120 : 240}
            />
          </Canv>
        </div>
        <Container ref={ref}>
          <div style={{ width: '100%' }}>
            <Canv2>
              <Canvas data={data3} height={224} width={868} widthAsMax={false} />
            </Canv2>

            <Canv>
              <SVGImage src={`data:image/svg+xml;base64,${dataw3b.toString('base64')}`} />
            </Canv>
            <div style={{ width: '100%', height: '0', marginBottom: '22.8%', marginTop: '-0.4%' }}></div>
            <Line
              height="4px"
              style={{
                bottom: '4px',
              }}
            />
            <Ball animate={inView} delay="5s" />
          </div>

          <div style={{ width: '100%', zIndex: '-3', marginTop: '-4.8%' }}>
            <Canv2 style={{ marginTop: '0.5%' }}>
              <Canvas data={data2} height={224} width={968} widthAsMax={false} />
            </Canv2>

            <Canv>
              <SVGImage src={`data:image/svg+xml;base64,${dataw2b.toString('base64')}`} />
            </Canv>
            <div style={{ width: '100%', height: '0', marginBottom: '19.7%', marginTop: '-1.4%' }}></div>
            <Line
              height="3px"
              style={{
                bottom: '4px',
              }}
            />
            <Ball animate={inView} delay="10s" />
          </div>

          <div style={{ width: '100%', zIndex: '0', marginTop: '-27%' }}>
            <Canv2>
              <Canvas data={data} height={224} width={768} widthAsMax={false} />
            </Canv2>
            <Canv>
              <SVGImage src={`data:image/svg+xml;base64,${datawb.toString('base64')}`} />
            </Canv>
            <div style={{ width: '100%', height: '0', marginBottom: '28%' }}></div>
            <Line
              style={{
                bottom: '4px',
              }}
            />
            <Ball animate={inView} />
          </div>
        </Container>
      </AutoColumn>
    </HeaderWrapper>
  )
}
