import { Route, Routes } from 'react-router-dom'
import styled from 'styled-components/macro'

//import bg from "../assets/svg/background.svg";
import ErrorBoundary from '../components/ErrorBoundary'
import Header from '../components/Header'
//import Polling from "../components/Header/Polling";
import Popups from '../components/Popups'
import { RouteDefinition, routes, useRouterConfig } from './RouteDefinitions'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  text-transform: lowercase;
`

const BodyWrapper = styled.div`
  display: grid;
  justify-content: center;
  background: ${({ theme }) => theme.primary1};
  background-image: linear-gradient(${({ theme }) => theme.primary1 + 'f9'}, ${({ theme }) => theme.primary1 + 'f9'}),
    url(${({ theme }) => theme.logo});
  //background-image: Logo;
  background-size: 100%;
  //background-repeat: no-repeat;
  flex-direction: column;
  width: 100%;
  //margin: auto;
  padding: 48px 0 0 0;
  align-items: center;
  flex: 1;
  z-index: 1;
  overflow: hidden;
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-self: space-between;
  position: fixed;
  top: 0;
  z-index: 2;
`

//<Route component={GoogleAnalyticsReporter} />

const Marginer = styled.div`
  margin-top: 5rem;
`

export default function App() {
  const routerConfig = useRouterConfig()

  return (
    <ErrorBoundary>
      <AppWrapper>
        <HeaderWrapper>
          <Header />
        </HeaderWrapper>
        <BodyWrapper>
          <Popups />
          <Routes>
            {routes.map((route: RouteDefinition) =>
              route.enabled(routerConfig) ? (
                <Route key={route.path} path={route.path} element={route.getElement(routerConfig)}>
                  {route.nestedPaths.map((nestedPath) => (
                    <Route path={nestedPath} key={`${route.path}/${nestedPath}`} />
                  ))}
                </Route>
              ) : null
            )}
          </Routes>
          <Marginer />
        </BodyWrapper>
      </AppWrapper>
    </ErrorBoundary>
  )
}
