import { createReducer } from '@reduxjs/toolkit'

import { SupportedLocale } from '../../constants/locales'
import { updateVersion } from '../global/actions'
import { updateMatchesDarkMode, updateUserClientSideRouter, updateUserDarkMode } from './actions'

const currentTimestamp = () => new Date().getTime()

interface UserState {
  // the timestamp of the last updateVersion action
  lastUpdateVersionTimestamp?: number

  userLocale: SupportedLocale | null

  matchesDarkMode: boolean // whether the dark mode media query matches

  userDarkMode: boolean | null // the user's choice for dark mode or light mode

  userClientSideRouter: boolean // whether routes should be calculated with the client side router only

  timestamp: number
  URLWarningVisible: boolean
}

const initialState: UserState = {
  userLocale: null,
  matchesDarkMode: true,
  userDarkMode: null,
  userClientSideRouter: false,
  timestamp: currentTimestamp(),
  URLWarningVisible: true,
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(updateVersion, (state) => {
      state.lastUpdateVersionTimestamp = currentTimestamp()
    })
    .addCase(updateUserDarkMode, (state, action) => {
      state.userDarkMode = action.payload.userDarkMode
      state.timestamp = currentTimestamp()
    })
    .addCase(updateMatchesDarkMode, (state, action) => {
      state.matchesDarkMode = action.payload.matchesDarkMode
      state.timestamp = currentTimestamp()
    })
    .addCase(updateUserClientSideRouter, (state, action) => {
      state.userClientSideRouter = action.payload.userClientSideRouter
    })
)
