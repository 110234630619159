import LOGO from 'assets/svg/logo.svg'
import React, { useMemo } from 'react'
import { Text, TextProps as TextPropsOriginal } from 'rebass'
import styled, {
  createGlobalStyle,
  css,
  DefaultTheme,
  ThemeProvider as StyledComponentsThemeProvider,
} from 'styled-components/macro'

import { useIsDarkMode } from '../state/user/hooks'
import { Breakpoints, Colors } from './styled'

//export * from './components'

type TextProps = Omit<TextPropsOriginal, 'css'>

export const BREAKPOINTS: Breakpoints = {
  xs: 396,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536,
  xxxl: 1920,
}

export const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 720,
  upToMedium: 960,
  upToLarge: 1280,
}

const mediaWidthTemplates: { [width in keyof typeof MEDIA_WIDTHS]: typeof css } = Object.keys(MEDIA_WIDTHS).reduce(
  (accumulator, size) => {
    ;(accumulator as any)[size] = (a: any, b: any, c: any) => css`
      @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
        ${css(a, b, c)}
      }
    `
    return accumulator
  },
  {}
) as any

const white = '#FFFFFF'
const black = '#000000'

function colors(darkMode: boolean): Colors {
  function hslMode(color: number): string {
    return `hsl(${color}, 100%, ${darkMode ? 40 : 60}%)`
  }
  return {
    darkMode,
    // base
    white,
    black,

    // text
    //text1: darkMode ? "#FFFFFF" : "#000000",
    //text2: darkMode ? "#C3C5CB" : "#565A69",
    //text3: darkMode ? "#8F96AC" : "#6E727D",
    //text4: darkMode ? "#B2B9D2" : "#C3C5CB",
    //text5: darkMode ? "#2C2F36" : "#EDEEF2",
    text1: darkMode ? '#FFFFFF' : '#000000',
    text2: darkMode ? '#C3C5CF' : '#E3E5EF',
    text3: darkMode ? '#D3D5DB' : '#C3C5CB',
    text4: '#B2B9D2',
    text5: '#2C2F36',

    // backgrounds / greys
    bg0: darkMode ? '#787878' : '#a8a8a8',
    bg1: darkMode ? '#484848' : '#787878',
    bg2: darkMode ? '#2C2F36' : '#EDEEF2',
    bg3: darkMode ? '#40444F' : '#787878',
    bg4: darkMode ? '#565A69' : '#888D9B',
    bg5: '#888D9B',
    bg6: darkMode ? '#1A2028' : '#6C7284',

    //specialty colors
    advancedBG: 'linear-gradient(to bottom, #57C757, #9070C7)',

    //primary colors
    primary1: darkMode ? '#4c566e' : '#8c9cb8',
    primary2: darkMode ? '#8c9cb8' : '#4c566e',
    primary3: darkMode ? '#8c9cb8' : '#dcecff',
    primary4: '#6c7c98',
    primary5: '#283848',

    // color text
    primaryText1: darkMode ? '#5090ea' : '#7040E0',

    // secondary colors
    secondary1: '#7040E0',
    secondary2: darkMode ? '#17000b26' : '#BBBBFA',
    secondary3: darkMode ? '#17000b26' : '#D0D0FA',

    // other
    red: hslMode(0),
    red1: darkMode ? '#FF4343' : '#DA2D2B',
    red2: darkMode ? '#F82D3A' : '#DF1F38',
    red3: '#FF21GD',
    orange: hslMode(30),
    turkey: hslMode(180),
    green: hslMode(120),
    green1: '#57C757',
    yellow: hslMode(60),
    yellow1: '#E3A507',
    yellow2: '#FF8F00',
    yellow3: '#F3B71E',
    blue: hslMode(240),
    blue1: darkMode ? '#1E1EFD' : '#0068FC',
    blue2: darkMode ? '#5199FF' : '#0068FC',
    purple: '#9070C7',
    magenta: hslMode(330),
    error: darkMode ? '#FD4040' : '#DF1F38',
    success: darkMode ? '#27AE60' : '#007D35',
    warning: '#FF8F00',

    logo: LOGO,

    // dont wanna forget these blue yet
    blue3: darkMode ? '#153d6f70' : '#C4D9F8',
    //blue5: darkMode ? "#153d6f70" : "#EBF4FF",
  }
}

function theme(darkMode: boolean): DefaultTheme {
  return {
    ...colors(darkMode),

    grids: {
      sm: 8,
      md: 12,
      lg: 24,
    },

    breakpoint: BREAKPOINTS,

    //shadows
    shadow1: darkMode ? '#000' : '#2F80ED',

    // media queries
    mediaWidth: mediaWidthTemplates,

    // css snippets
    flexColumnNoWrap: css`
      display: flex;
      flex-flow: column nowrap;
    `,
    flexRowNoWrap: css`
      display: flex;
      flex-flow: row nowrap;
    `,
  }
}

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
  const darkMode = useIsDarkMode()

  const themeObject = useMemo(() => theme(darkMode), [darkMode])

  return <StyledComponentsThemeProvider theme={themeObject}>{children}</StyledComponentsThemeProvider>
}

const TextWrapper = styled(Text)<{ color: keyof Colors }>`
  color: ${({ color, theme }) => (theme as any)[color]};
  text-transform: lowercase;
`

export const TYPE = {
  Main(props: TextProps) {
    return <TextWrapper fontWeight={500} color="text2" {...props} />
  },
  Link(props: TextProps) {
    return <TextWrapper fontWeight={500} color="text3" {...props} />
  },
  Label(props: TextProps) {
    return <TextWrapper fontWeight={600} color="primary4" {...props} />
  },
  Black(props: TextProps) {
    return <TextWrapper fontWeight={500} color="black" {...props} />
  },
  White(props: TextProps) {
    return <TextWrapper fontWeight={500} color="white" {...props} />
  },
  Body(props: TextProps) {
    return <TextWrapper fontWeight={500} color="text1" {...props} />
  },
  LargeHeader(props: TextProps) {
    return <TextWrapper fontWeight={600} fontSize={24} {...props} />
  },
  MediumHeader(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={20} {...props} />
  },
  SubHeader(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={14} color="text2" {...props} />
  },
  Small(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={11} color="text2" {...props} />
  },
  Red(props: TextProps) {
    return <TextWrapper fontWeight={500} color="red" {...props} />
  },
  Green(props: TextProps) {
    return <TextWrapper fontWeight={500} color="green" {...props} />
  },
  Blue(props: TextProps) {
    return <TextWrapper fontWeight={500} color="blue" {...props} />
  },
  Yellow(props: TextProps) {
    return <TextWrapper fontWeight={500} color="yellow" {...props} />
  },
  DarkGray(props: TextProps) {
    return <TextWrapper fontWeight={500} color="text3" {...props} />
  },
  Gray(props: TextProps) {
    return <TextWrapper fontWeight={500} color="bg3" {...props} />
  },
  Italic(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={12} fontStyle="italic" color="text2" {...props} />
  },
  Error({ error, ...props }: { error: boolean } & TextProps) {
    return <TextWrapper fontWeight={500} color={error ? 'red1' : 'text2'} {...props} />
  },
}

export const ThemedGlobalStyle = createGlobalStyle`
html {
  color: ${({ theme }) => theme.text1};
  background-color: ${({ theme }) => theme.bg1} !important;
}

a {
 color: ${({ theme }) => theme.blue1}; 
}
`
