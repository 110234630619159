//import { RowFixed } from "components/Row";
import React from 'react'
import styled, { css, keyframes } from 'styled-components/macro'

import DogeLogo from '../../assets/images/doge.png'
import PepeLogo from '../../assets/images/pepe.jpg'
import ShibLogo from '../../assets/images/shib.png'
import Logo from '../../assets/svg/logo.svg'
import Canvas from '../Canvas'
import { AutoColumn } from '../Column'
import { RowBetween } from '../Row'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const strokeDash = keyframes`
  to {
    stoke-dashoffset: 1000;
  }
`

const loadingAnimation = keyframes`
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`

const FeatherSVG = styled.path`
  path {
	stroke: black;
    stroke-width: 2;
    fill: black;
    d="M1076 128 Q1076 140 968 136 Q972 172 880 220 L864 160 L868 228 Q788 244 708 244 L700 192 L696 240 Q592 256 492 256 L520 184 L480 252 Q390 252 300 240  L296 220 L288 240   Q240 240 148 220 L160 160 L140 212  Q8 188 4 128 Q4 56 80 28 L96 52 L92 20  Q108 0 224 4 L220 48 L232 8 Q240 4 260 4 L264 20 L268 4 Q360 0 404 8 L388 68 L416 4 Q528 0 648 12 L652 72 L660 16 Q760 8 856 24 L852 64 L868 32 Q972 40 968 116 Q1076 112 1076 128Z";
  }
`

const ChartSVG = styled.svg`
  stroke-linejoin: round;
  opacity: 1;
  cursor: pointer;
  :hover {
    transform: scale(1.2);
  }
`

const LoadedSVG = styled.svg<{ height: string; width: string }>`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
`

const SpinnedSVG = styled.svg<{ size: string; stroke?: string }>`
  animation: 2s ${rotate} linear infinite;
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  path {
    stroke: ${({ stroke, theme }) => stroke ?? theme.primary2};
  }
`

const Stop = styled.stop<{ color?: string }>`
  stop-color: ${({ color, theme }) => color ?? theme.primary2};
`

const Container = styled(RowBetween)`
  padding: 0 16px 0 4px;
  position: relative;
  margin-left: 32px;
  width: 80%;
`

const ContainerTrash = styled(AutoColumn)`
  margin-top: 0%;
  width: 100%;
`

const hairf =
  'M472 48 q0 -12 12 -20 q-4 8 0 12 q0 -14 16 -16 q-8 4 -4 12 q4 -16 20 -16 q-8 12 -4 18 q8 -22 28 -18 q-20 12 -8 40'

const heads = [
  'M392 388 Q400 240 410 140 Q420 40 500 40 Q580 40 590 140 Q600 300 608 388',
  'M392 388 Q384 320 292 208 Q240 100 312 72 Q400 28 464 140 Q608 320 608 388',
  'M392 388 Q384 320 260 308 Q160 300 160 224 Q160 140 260 140 Q540 140 608 388',
  'M392 388 Q392 320 528 160 Q616 36 700 76 Q768 116 720 200 Q616 320 608 388',
  'M392 388 Q460 140 740 140 Q840 140 840 224 Q840 300 740 308 Q604 320 608 388',
  'M392 388 Q400 220 412 100 Q420 0 500 0 Q580 0 588 100 Q600 220 608 388',
  'M392 388 Q400 360 412 300 Q420 180 500 180 Q580 180 588 300 Q600 360 608 388',
]

const headshadows = [
  '',
  'M392 388 Q384 320 292 208 Q392 236 392 388',
  'M392 388 Q384 320 260 308 Q160 300 160 224 Q180 288 260 280 Q372 300 392 388',
  'M608 388 Q608 236 720 200 Q616 320 608 388',
  'M608 388 Q604 320 740 308 Q840 300 840 224 Q820 288 740 280 Q588 300 608 388',
  '',
  '',
]

//M4 44 Q40 20 76 44 Q80 48 76 60 Q56 108 40 108 Q24 108 4 60 Q0 48 4 44Z

const beakshadows = [
  'M2 48 Q40 156 78 48 Q72 128 40 136 Q8 128 2 48',
  'M40 104 Q-20 128 2 48 Q8 56 24 72 Q28 84 40 104',
  'M40 104 Q-28 140 2 48 Q8 56 24 72 Q28 84 40 104',
  'M40 104 Q100 128 78 48 Q72 56 56 72 Q52 84 40 104',
  'M40 104 Q108 140 78 48 Q72 56 56 72 Q52 84 40 104',
  'M2 48 Q40 156 78 48 Q72 128 40 136 Q8 128 2 48',
  'M2 48 Q40 156 78 48 Q72 128 40 136 Q8 128 2 48',
]

const faceTransforms = [
  '',
  'translate(-124,328) rotate(-36)',
  'translate(120,728) rotate(-90)',
  'translate(324,-252) rotate(36)',
  'translate(880,-272) rotate(90)',
  'translate(2,-40)',
  'translate(2,140)',
]

const feather =
  'M1076 128 Q1076 140 968 136 Q972 172 880 220 L864 160 L868 228 Q788 244 708 244 L700 192 L696 240 Q592 256 492 256 L520 184 L480 252 Q390 252 300 240  L296 220 L288 240   Q240 240 148 220 L160 160 L140 212  Q8 188 4 128 Q4 56 80 28 L96 52 L92 20  Q108 0 224 4 L220 48 L232 8 Q240 4 260 4 L264 20 L268 4 Q360 0 404 8 L388 68 L416 4 Q528 0 648 12 L652 72 L660 16 Q760 8 856 24 L852 64 L868 32 Q972 40 968 116 Q1076 112 1076 128Z'
const lash1 =
  'M64 8 C76 8 72 16 72 16 Q8 24 8 64 Q8 100 68 112 C72 112 72 120 64 120 Q0 104 0 64 Q0 20 64 8Z M-12 48 Q-12 12 36 0 Q56 -4 64 8 Q0 20 0 64 Q0 104 64 120 Q64 120 64 120 Q16 128 -4 96'
const lash2 =
  'M96 20 C100 20 100 28 96 28 Q8 24 8 64 Q8 100 92 100 C96 100 96 108 92 108 Q0 108 0 64 Q0 16 96 20Z M-12 48 Q8 0 44 -2 Q80 -4 96 20 Q0 16 0 64 Q0 108 92 108 Q72 120 52 120 Q16 120 -4 96'
const lash3 =
  'M108 56 C112 56 112 64 108 64 Q8 64 8 64 Q8 64 108 64 C112 64 112 72 108 72 Q0 72 0 64 Q0 56 108 56Z M-12 48 Q8 0 48 -2 Q100 0 108 56 Q0 56 0 64 Q0 72 108 72 Q96 120 52 120 Q16 120 -4 96'
const lash4 =
  'M64 -20 C76 -24 76 -16 68 -12 Q8 24 8 64 Q8 100 68 140 C76 144 76 152 64 148 Q0 104 0 64 Q0 20 64 -20Z M-12 48 Q-8 12 36 -8 Q44 -12 64 -20 Q0 20 0 64 Q0 104 64 148 Q60 148 40 140 Q12 128 -4 96'
const globe = 'M56 14 c30 0 50 25 50 50 c0 30 -25 50 -50 50 c-30 0 -50 -25 -50 -50 c0 -30 25 -50 50 -50'

const lash21 =
  'M-32 20 Q-36 40 -32 40 Q16 40 44 40 Q96 40 116 40 Q120 40 116 20 M-32 108 Q-36 108 -32 108 Q16 108 44 108 Q96 108 116 108 Q120 108 116 108'
const lash22 =
  'M-32 20 Q-36 40 -32 60 Q16 76 44 76 Q96 76 116 88 Q120 40 116 20 M-32 108 Q-36 92 -32 60 Q16 77 44 77 Q96 77 116 84 Q120 92 116 108'

const shadow1R =
  'M68 112 C72 112 72 120 60 120 Q60 120 60 120 Q20 220 0 62 Q56 120 106 62 c0 30 -25 50 -50 50 c-30 0 -50 -25 -50 -50 Q8 100 68 112Z'
const shadow2R =
  'M92 100 C96 100 96 108 92 108 Q80 116 64 120 Q20 220 0 62 Q56 120 106 62 c0 30 -25 50 -50 50 c-30 0 -50 -25 -50 -50 Q8 100 92 100Z'
const shadow3R =
  'M108 64 C112 64 112 72 108 72 Q96 116 64 120 Q20 220 0 62 Q56 120 106 62 c0 30 -25 50 -50 50 c-30 0 -50 -25 -50 -50 Q8 68 108 64Z'
const shadow1L =
  'M68 112 C72 112 72 120 60 120 Q16 128 -4 96Q0 80 6 62 Q56 120 106 62 c0 30 -25 50 -50 50 c-30 0 -50 -25 -50 -50 Q8 100 68 112Z'
const bodyF = 'M608 387 Q730 550 690 620 Q500 900 310 620 Q270 550 392 387'

const tailF =
  'M420 700 Q420 780 440 860    Q460 868 480 860   Q500 872 520 860  Q540 868 560 860  Q580 780 580 700Z       M480 860 Q492 752 480 700   M520 700 Q508 752 520 860'

const footF =
  'M58 128 Q54 180 58 218     Q70 225 72 233 Q80 235 82 245 Q80 250 76 250 Q68 245 67 240 Q56 236 56 228    Q58 236 55 245 Q58 250 54 260 Q50 265 46 260 Q42 250 45 245 Q42 238 44 228    Q44 236 33 240 Q32 245 24 250 Q20 250 18 245 Q20 235 28 233 Q30 225 42 218     Q42 218 42 218 Q42 218 42 218 Q42 218 42 218 Q42 218 42 218 Q42 218 42 218    Q46 180 42 128 Q50 116 58 128Z    M78 245 l6 8 l-8 -7 l2 -1Z   M49 258 l1 10 l1 -10 -2 0Z   M22 245 l-6 8 l8 -7 -2 -1Z   M42 218 l0 0 l0 0 l0 0Z'

const winkAni = keyframes`
    0% {
		d: path("${lash1}");
    }
    0.5% {
		d: path("${lash2}");
    }
	1% {
		d: path("${lash3}");
    }
	1.5% {
		d: path("${lash2}");
    }
	2% {
		d: path("${lash1}");
    }
	100% {
		d: path("${lash1}");
    }
  `

const winkAni2 = keyframes`
    0% {
		d: path("${shadow1R}");
    }
    0.5% {
		d: path("${shadow2R}");
    }
	1% {
		d: path("${shadow3R}");
    }
	1.5% {
		d: path("${shadow2R}");
    }
	2% {
		d: path("${shadow1R}");
    }
	100% {
		d: path("${shadow1R}");
    }
  `

const BigEyeLashAni = keyframes`
    0% {
		d: path("${lash1}");
    }
    15% {
		d: path("${lash4}");
    }
	30% {
		d: path("${lash1}");
    }
	100% {
		d: path("${lash1}");
    }
  `

const BigEyeGlobeAni = keyframes`
    0% {
		d: path("${globe}");
    }
    15% {
		d: path("M88 -16 c48 0 80 40 80 80 c0 48 -40 80 -80 80 c-48 0 -80 -40 -80 -80 c0 -48 40 -80 80 -80");
    }
	30% {
		d: path("${globe}");
    }
	100% {
		d: path("${globe}");
    }
  `

const BigEyeIrisAni = keyframes`
    0% {
		scale: 1;
    }
    15% {
		scale: 1.7;
    }
	30% {
		scale: 1;
    }
	100% {
		scale: 1;
    }
  `

const Logo1 = styled.image<{ delay?: string; animate?: boolean }>`
  ${({ animate }) =>
    animate
      ? css`
          animation: ${BigEyeIrisAni} 10s linear infinite;
        `
      : null};
  animation-delay: ${({ delay }) => delay ?? '0s'};
`

const DoveSVG = styled.svg`
  preserveaspectratio: xMidYMid meet;
`

const SunSVG = styled.svg`
  preserveaspectratio: xMidYMid meet;
`

const StrokedSVG = styled.svg<{ len: number }>`
  path {
    stroke-dasharray: ${({ len }) => len};
    stroke-dashoffset: 0;
    animation: dash 1.5s linear infinite;
  }
`

const GlobePath = styled.path<{ delay?: string; animate?: boolean }>`
  //d: path("${lash1}");
  //stroke: blue;
  ${({ animate }) =>
    animate
      ? css`
          animation: ${BigEyeGlobeAni} 10s linear infinite;
        `
      : null};
  animation-delay: ${({ delay }) => delay};
`

const LashPath = styled.path<{ delay?: string; animate?: boolean }>`
  d: path('${lash1}');
  //stroke: blue;
  ${({ animate }) =>
    animate
      ? css`
          animation: ${winkAni} 30s linear infinite;
        `
      : null};
  animation-delay: ${({ delay }) => delay};
`

const ShadowPath = styled.path<{ delay?: string; animate?: boolean }>`
  d: path('${shadow1R}');
  //stroke: blue;
  ${({ animate }) =>
    animate
      ? css`
          animation: ${winkAni2} 30s linear infinite;
        `
      : null};
  animation-delay: ${({ delay }) => delay};
`

const LashPathB = styled.path<{ delay?: string; animate?: boolean }>`
  //d: path("${lash1}");
  //stroke: blue;
  ${({ animate }) =>
    animate
      ? css`
          animation: ${BigEyeLashAni} 10s linear infinite;
        `
      : null};
  animation-delay: ${({ delay }) => delay};
`

const CloudPath = styled.path`
  fill: ${({ theme }) => theme.primary2};
`

const trashAni1 = keyframes`
    0% {
		opacity: 1;
    }
	15.6% {
		opacity: 1;
    }
	16.6% {
		opacity: 0;
    }
	98% {
		opacity: 0;
    }
	99% {
		opacity: 1;
    }
	100% {
		opacity: 1;
    }
  `

const trashAni2 = keyframes`
	14.6% {
		opacity: 0;
    }
    15.6% {
		opacity: 1;
    }
	32.3% {
		opacity: 1;
    }
	33.3% {
		opacity: 0;
    }
	81.4% {
		opacity: 0;
    }
	82.4% {
		opacity: 1;
    }
	99% {
		opacity: 1;
    }
	100% {
		opacity: 0;
    }
  `

const trashAni3 = keyframes`
    31.3% {
		opacity: 0;
    }
    32.3% {
		opacity: 1;
    }
	49% {
		opacity: 1;
    }
	50% {
		opacity: 0;
    }
	64.6% {
		opacity: 0;
    }
	65.6% {
		opacity: 1;
    }
	82.4% {
		opacity: 1;
    }
	83.4% {
		opacity: 0;
    }
	100% {
		opacity: 0;
    }
  `

const trashAni4 = keyframes`
    48% {
		opacity: 0;
    }
    49% {
		opacity: 1;
    }
	65.6% {
		opacity: 1;
    }
	66.6% {
		opacity: 0;
    }
	100% {
		opacity: 0;
    }
  `

const Canvtrash = styled.div`
  //margin-top: -24%;
  //top: -28%;
  //margin-left: 20%;
  //max-width: 80%;
  //height: 100%;
  width: 100%;
  position: absolute;
  opacity: 0;
`

const Canvtrash1 = styled(Canvtrash)<{ duray: string; animate?: boolean }>`
  opacity: 1;
  ${({ animate }) =>
    animate
      ? css`
          animation: ${trashAni1} linear infinite;
        `
      : null};
  animation-duration: ${({ duray }) => duray};
`

const Canvtrash2 = styled(Canvtrash)<{ duray: string; animate?: boolean }>`
  ${({ animate }) =>
    animate
      ? css`
          animation: ${trashAni2} linear infinite;
        `
      : null};
  animation-duration: ${({ duray }) => duray};
`

const Canvtrash3 = styled(Canvtrash)<{ duray: string; animate?: boolean }>`
  ${({ animate }) =>
    animate
      ? css`
          animation: ${trashAni3} linear infinite;
        `
      : null};
  animation-duration: ${({ duray }) => duray};
`

const Canvtrash4 = styled(Canvtrash)<{ duray: string; animate?: boolean }>`
  ${({ animate }) =>
    animate
      ? css`
          animation: ${trashAni4} linear infinite;
        `
      : null};
  animation-duration: ${({ duray }) => duray};
`

const ContainerDance = styled.div`
  //justify-content: center;
  position: relative;
  width: 100%;
`

const danceAni1 = keyframes`
    0% {
		opacity: 1;
    }
	11.5% {
		opacity: 1;
    }
	12.5% {
		opacity: 0;
    }
	98% {
		opacity: 0;
    }
	99% {
		opacity: 1;
    }
	100% {
		opacity: 1;
    }
  `

const danceAni2 = keyframes`
    0% {
		opacity: 0;
    }
	10.5% {
		opacity: 0;
    }
	11.5% {
		opacity: 1;
    }
	24% {
		opacity: 1;
    }
	25% {
		opacity: 0;
    }
	100% {
		opacity: 0;
    }
  `

const danceAni3 = keyframes`
    0% {
		opacity: 0;
    }
	23% {
		opacity: 0;
    }
	24% {
		opacity: 1;
    }
	36.5% {
		opacity: 1;
    }
	37.5% {
		opacity: 0;
    }
	100% {
		opacity: 0;
    }
  `

const danceAni4 = keyframes`
    0% {
		opacity: 0;
    }
	35.5% {
		opacity: 0;
    }
	36.5% {
		opacity: 1;
    }
	49% {
		opacity: 1;
    }
	50% {
		opacity: 0;
    }
	100% {
		opacity: 0;
    }
  `

const danceAni5 = keyframes`
    0% {
		opacity: 0;
    }
	48% {
		opacity: 0;
    }
	49% {
		opacity: 1;
    }
	61.5% {
		opacity: 1;
    }
	62.5% {
		opacity: 0;
    }
	100% {
		opacity: 0;
    }
  `

const danceAni6 = keyframes`
    0% {
		opacity: 0;
    }
	60.5% {
		opacity: 0;
    }
	61.5% {
		opacity: 1;
    }
	74% {
		opacity: 1;
    }
	75% {
		opacity: 0;
    }
	100% {
		opacity: 0;
    }
  `

const danceAni7 = keyframes`
    0% {
		opacity: 0;
    }
	73% {
		opacity: 0;
    }
	74% {
		opacity: 1;
    }
	86.5% {
		opacity: 1;
    }
	87.5% {
		opacity: 0;
    }
	100% {
		opacity: 0;
    }
  `

const danceAni8 = keyframes`
    0% {
		opacity: 0;
    }
	85.5% {
		opacity: 0;
    }
	86.5% {
		opacity: 1;
    }
	99% {
		opacity: 1;
    }
	100% {
		opacity: 0;
    }
  `

const CanvDance1 = styled(Canvtrash)<{ duray: string; animate?: boolean }>`
  opacity: 1;
  ${({ animate }) =>
    animate
      ? css`
          animation: ${danceAni1} linear infinite;
        `
      : null};
  animation-duration: ${({ duray }) => duray};
`

const CanvDance2 = styled(Canvtrash)<{ duray: string; animate?: boolean }>`
  ${({ animate }) =>
    animate
      ? css`
          animation: ${danceAni2} linear infinite;
        `
      : null};
  animation-duration: ${({ duray }) => duray};
`

const CanvDance3 = styled(Canvtrash)<{ duray: string; animate?: boolean }>`
  ${({ animate }) =>
    animate
      ? css`
          animation: ${danceAni3} linear infinite;
        `
      : null};
  animation-duration: ${({ duray }) => duray};
`

const CanvDance4 = styled(Canvtrash)<{ duray: string; animate?: boolean }>`
  ${({ animate }) =>
    animate
      ? css`
          animation: ${danceAni4} linear infinite;
        `
      : null};
  animation-duration: ${({ duray }) => duray};
`

const CanvDance5 = styled(Canvtrash)<{ duray: string; animate?: boolean }>`
  ${({ animate }) =>
    animate
      ? css`
          animation: ${danceAni5} linear infinite;
        `
      : null};
  animation-duration: ${({ duray }) => duray};
`

const CanvDance6 = styled(Canvtrash)<{ duray: string; animate?: boolean }>`
  ${({ animate }) =>
    animate
      ? css`
          animation: ${danceAni6} linear infinite;
        `
      : null};
  animation-duration: ${({ duray }) => duray};
`

const CanvDance7 = styled(Canvtrash)<{ duray: string; animate?: boolean }>`
  ${({ animate }) =>
    animate
      ? css`
          animation: ${danceAni7} linear infinite;
        `
      : null};
  animation-duration: ${({ duray }) => duray};
`

const CanvDance8 = styled(Canvtrash)<{ duray: string; animate?: boolean }>`
  ${({ animate }) =>
    animate
      ? css`
          animation: ${danceAni8} linear infinite;
        `
      : null};
  animation-duration: ${({ duray }) => duray};
  position: relative;
`

const canvAni1 = keyframes`
    0% {
		opacity: 1;
    }
	50% {
		opacity: 1;
    }
	51% {
		opacity: 0;
    }
	55% {
		opacity: 0;
    }
	56% {
		opacity: 1;
    }
	100% {
		opacity: 1;
    }
  `

const canvAni2 = keyframes`
    0% {
		opacity: 0;
    }
	49% {
		opacity: 0;
    }
	50% {
		opacity: 1;
    }
	56% {
		opacity: 1;
    }
	57% {
		opacity: 0;
    }
	100% {
		opacity: 0;
    }
  `

const Canv = styled.div`
  left: 15%;
  bottom: -5%;
  position: absolute;
  width: 100%;
`

const Canv1 = styled(Canv)<{ duray: string; animate?: boolean }>`
  opacity: 1;
  z-index: 1;
  ${({ animate }) =>
    animate
      ? css`
          animation: ${canvAni1} linear 3s infinite;
        `
      : null};
  animation-delay: ${({ duray }) => duray};
`

const Canv2 = styled(Canv)<{ duray: string; animate?: boolean }>`
  opacity: 0;
  z-index: 1;
  ${({ animate }) =>
    animate
      ? css`
          animation: ${canvAni2} linear 3s infinite;
        `
      : null};
  animation-delay: ${({ duray }) => duray};
`

const shitPath1 = 'M60 16  Q36 12 32 60 Q24 124 64 124 Q108 124 96 60 Q88 20 60 16Z'
const shitPath2 = 'M60 40  Q44 40 44 68 Q40 104 64 104 Q88 104 80 60 Q76 40 60 40Z'

const ShitAni1 = keyframes`
    0% {
		d: path("${shitPath1}");
    }
	60% {
		d: path("${shitPath1}");
    }
    63% {
		d: path("M60 8 Q4 0 24 68 Q0 176 68 144 Q164 168 116 72 Q136 0 60 8Z");
    }
	100% {
		d: path("M60 8 Q4 0 24 68 Q0 176 68 144 Q164 168 116 72 Q136 0 60 8Z");
    }
  `

const ShitAni2 = keyframes`
    0% {
		d: path("${shitPath2}");
    }
    60% {
		d: path("${shitPath2}");
    }
	63% {
		d: path("M60 36 Q20 20 36 72 Q20 156 72 120 Q140 136 92 68 Q112 16 60 36Z");
    }
	100% {
		d: path("M60 36 Q20 20 36 72 Q20 156 72 120 Q140 136 92 68 Q112 16 60 36Z");
    }
  `

const ShitTransform = keyframes`
    0% {
		rotate: 100deg;
		left: 49%;
		top: 28%;
    }
	48% {
		rotate: 100deg;
		left: 49%;
		top: 28%;
    }
	53% {
		rotate: 80deg;
		left: 28%;
		top: 28%;
    }
    60% {
		rotate: 20deg;
		left: 5%;
		top: 68%;
    }
	100% {
		rotate: 20deg;
		left: 5%;
		top: 68%;
    }
  `

const ShitSVG = styled.svg<{ delay: string; animate?: boolean }>`
  position: absolute;
  left: 49%;
  top: 28%;
  width: 15%;
  ${({ animate }) =>
    animate
      ? css`
          animation: ${ShitTransform} 3s linear infinite;
        `
      : null};
  animation-delay: ${({ delay }) => delay};
`

const ShitPath1 = styled.path<{ delay?: string; animate?: boolean }>`
  ${({ animate }) =>
    animate
      ? css`
          animation: ${ShitAni1} 3s linear infinite;
        `
      : null};
  animation-delay: ${({ delay }) => delay};
`

const ShitPath2 = styled.path<{ delay?: string; animate?: boolean }>`
  ${({ animate }) =>
    animate
      ? css`
          animation: ${ShitAni2} 3s linear infinite;
        `
      : null};
  animation-delay: ${({ delay }) => delay};
`

const CoinsSVG = styled.svg`
  margin-top: 110%;
  margin-bottom: 5%;
  width: 50%;
`

/**
 * Takes in custom size and stroke for circle color, default to primary color as fill,
 * need ...rest for layered styles on top
 */
function getColor(id: number): string {
  if (id == 0) return '#282828'
  const digits = '1469be'
  return `#${digits[id % 6]}8${digits[Math.floor(id / 6) % 6]}8${digits[Math.floor(id / 36) % 6]}8`
}

function getEyeColor(id: number): string {
  if (id % 16 >= 8) {
    if (id >= 108) return getColor(35 - Math.floor(id / 36) * 6)
    else return getColor(35 - Math.floor(id / 36) * 6)
  }
  return getColor((id + 108) % 216)
}

function getBeakColor(id: number): string {
  if (id % 32 >= 16) return '#8c7c88'
  if (id >= 108) return getColor(35 + Math.floor((id - 108) / 36) * 36 - Math.floor((id - 108) / 36) * 6)
  return getColor(23 + Math.floor(id / 36) * 6)
}

function mixColor(color1: string, color2: string): string {
  const HEX_DIGITS = '0123456789abcdef'
  return `#${HEX_DIGITS[Math.floor((hexToNumber(color1[1]) + hexToNumber(color2[1])) / 2)]}8${
    HEX_DIGITS[Math.floor((hexToNumber(color1[3]) + hexToNumber(color2[3])) / 2)]
  }8${HEX_DIGITS[Math.floor((hexToNumber(color1[5]) + hexToNumber(color2[5])) / 2)]}8`
}

// ****
// @dev Gets the current nonce for a token ID and then increments it, returning the original value
//
function lightenColor(color: string): string {
  const HEX_DIGITS = '0123456789abcdef'
  return `#${color[1] < 'e' ? HEX_DIGITS[hexToNumber(color[1]) + 2] : color[1]}8${
    color[3] < 'e' ? HEX_DIGITS[hexToNumber(color[3]) + 2] : color[3]
  }8${color[5] < 'e' ? HEX_DIGITS[hexToNumber(color[5]) + 2] : color[5]}8`
}

// ****
// @dev Gets the current nonce for a token ID and then increments it, returning the original value
//
function darkenColor(color: string): string {
  const HEX_DIGITS = '0123456789abcdef'
  return `#${color[1] > '2' ? HEX_DIGITS[hexToNumber(color[1]) - 2] : color[1]}8${
    color[3] > '2' ? HEX_DIGITS[hexToNumber(color[3]) - 2] : color[3]
  }8${color[5] > '2' ? HEX_DIGITS[hexToNumber(color[5]) - 2] : color[5]}8`
}

function hexToNumber(hex: string): number {
  const buffer = Array.from(hex)
  if (hex > '9') return hex.charCodeAt(0) - 87
  return Number(hex)
}

function isDark(color: string): boolean {
  return color[1] < '9' || color[3] < '9' || color[5] < '9'
}

/**
 * Takes in custom size and stroke for circle color, default to primary color as fill,
 * need ...rest for layered styles on top
 */
function getDefs(type: number, id: number): string {
  if (id == 0 || id > 46656) {
    return `<style>.pr${type}0{fill:#6c7c98}.se${type}0{fill:#6c7c98}.he${type}0{fill:#6c7c98}.bo${type}0{fill:#6c7c98}.ta${type}0{fill:#5c6880}.wr${type}0{fill:#7c90b0}.wl${type}0{fill:#7c90b0}.lr${type}0{fill:#6c7c98}.ll${type}0{fill:#6c7c98}.er${type}0{fill:#f79717}.el${type}0{fill:#f79717}.sr${type}0{fill:#6c7c98}.sl${type}0{fill:#6c7c98}.be${type}0{fill:#8c7c88}.hr${type}0{fill:#6c7c98;stroke-width:1}.sh{stroke:none;opacity:.1}</style>`
  }
  const id1: number = Math.floor(--id / 216) % 216
  const id2: number = id % 216
  const color1: string = getColor(id1)
  const color2: string = getColor(id2)

  if (id1 == id2) {
    ++id
    return `<style>.pr${type}${id}{fill:${color1}}.se${type}${id}{fill:${color1}}.he${type}${id}{fill:${color1}}.bo${type}${id}{fill:${color1}}.ta${type}${id}{fill:${color1}}.wr${type}${id}{fill:${color1}}.wl${type}${id}{fill:${color1}}.lr${type}${id}{fill:${color1}}.ll${type}${id}{fill:${color1}}.er${type}${id}{fill:#ec7}.el${type}${id}{fill:#ec7}.sr${type}${id}{fill:${color1}}.sl${type}${id}{fill:${color1}}.be${type}${id}{fill:#ec7}.hr${type}${id}{fill:${color1};stroke-width:1}.sh{stroke:none;opacity:.1}</style>`
  }

  const colorEyeR: string = getEyeColor(id2)
  const colorEyeL: string = id % 30 == 0 ? getEyeColor(id1) : colorEyeR
  const colorBeak: string = getBeakColor(id2)
  ++id

  if (id2 % 2 == 0) {
    const colorFeather: string = isDark(color1) ? '#282828' : '#d8d8d8' //id1 > 120 && id2 > 120 ? '#d8d8d8' : '#282828'
    if (id2 % 4 == 0) {
      const color3: string = mixColor(color2, getColor((id1 + id2 + 162) % 216))
      const color4: string = mixColor(color1, getColor((id1 + id2 + 54) % 216))
      const colorWing = lightenColor(color2)
      return `<style>.pr${type}${id}{fill:${color1}}.se${type}${id}{fill:${color2}}.he${type}${id}{fill:url(#hed${type}${id})}.bo${type}${id}{fill:url(#bdy${type}${id})}.ta${type}${id}{fill:${colorFeather}}.wr${type}${id}{fill:${colorWing}}.wl${type}${id}{fill:${colorWing}}.lr${type}${id}{fill:${color1}}.ll${type}${id}{fill:${color1}}.er${type}${id}{fill:${colorEyeR}}.el${type}${id}{fill:${colorEyeL}}.sr${type}${id}{fill:${color2}}.sl${type}${id}{fill:${color2}}.be${type}${id}{fill:${colorBeak}}.hr${type}${id}{fill:${color2};stroke-width:1}.sh{stroke:none;opacity:.1}</style><linearGradient id="hed${type}${id}" x1="0%" y1="${
        type == 0 ? 0 : 0
      }%" x2="${
        type == 0 ? 0 : 0
      }%" y2="100%"><stop offset="60%" stop-color="${color2}" /><stop offset="80%" stop-color="${color3}" /><stop offset="100%" stop-color="${color4}" /></linearGradient><linearGradient id="bdy${type}${id}" x1="0%" y1="0%" x2="0%" y2="100%"><stop offset="0%" stop-color="${color4}" /><stop offset="20%" stop-color="${color1}" /></linearGradient>`
    }
    const colorWing: string = darkenColor(color2)
    return `<style>.pr${type}${id}{fill:${color1}}.se${type}${id}{fill:${color2}}.he${type}${id}{fill:url(#hed${type}${id})}.bo${type}${id}{fill:${color1}}.ta${type}${id}{fill:${colorFeather}}.wr${type}${id}{fill:${colorWing}}.wl${type}${id}{fill:${colorWing}}.lr${type}${id}{fill:${color1}}.ll${type}${id}{fill:${color1}}.er${type}${id}{fill:${colorEyeR}}.el${type}${id}{fill:${colorEyeL}}.sr${type}${id}{fill:${color2}}.sl${type}${id}{fill:${color2}}.be${type}${id}{fill:${colorBeak}}.hr${type}${id}{fill:${color2};stroke-width:1}.sh{stroke:none;opacity:.1}</style><linearGradient id="hed${type}${id}" x1="0%" y1="${
      type == 0 ? 0 : 0
    }%" x2="${
      type == 0 ? 0 : 0
    }%" y2="100%"><stop offset="60%" stop-color="${color2}" /><stop offset="100%" stop-color="${color1}" /></linearGradient>`
  } else {
    const pattern: string = id2 % 4 == 3 ? '  0 1' : '0 1 0'
    const base: string = ['002', '005', '01', '02', '04', '07', '11', '15'][Math.floor((id2 % 32) / 4)]
    return (
      `<style>.pr${type}${id}{fill:${color1}}.se${type}${id}{fill:${color2}}.he${type}${id}{fill:url(#hed${type}${id})}.bo${type}${id}{fill:url(#bdy${type}${id})}.ta${type}${id}{fill:${color2}}.wr${type}${id}{fill:url(#wgR${type}${id})}.wl${type}${id}{fill:url(#wgL${type}${id})}.lr${type}${id}{fill:url(#lgR${type}${id})}.ll${type}${id}{fill:url(#lgL${type}${id})}.er${type}${id}{fill:${colorEyeR}}.el${type}${id}{fill:${colorEyeL}}.sr${type}${id}{fill:url(#lsR${type}${id})}.sl${type}${id}{fill:url(#lsL${type}${id})}.be${type}${id}{fill:${colorBeak}}.hr${type}${id}{fill:url(#hed${type}${id});stroke-width:1}.sh{stroke:none;opacity:.1}</style>` +
      `<filter id="filter${type}${id}"><feTurbulence type="fractalNoise" baseFrequency=".${base}" seed="${id}"/><feComponentTransfer><feFuncA type="discrete" tableValues="${pattern}"/></feComponentTransfer><feComposite operator="in" in="SourceGraphic" /></filter><pattern id="pa${type}${id}" preserveAspectRatio="none" patternUnits="userSpaceOnUse" ><rect width="1280" height="1280" class="se${type}${id}" /><rect width="1280" height="1280" class="pr${type}${id}" filter="url(#filter${type}${id})" /></pattern><pattern id="pa2${type}${id}" x="-${
        type == 0 ? 428 : type == 1 ? 100 : 230
      }" href="#pa${type}${id}"/><pattern id="pa3${type}${id}" href="#pa${type}${id}" width="1280" height="1280" /><pattern id="hed${type}${id}" href="#pa2${type}${id}" y="100" width="1280" height="588" patternTransform="translate(0,388)"  /><pattern id="bdy${type}${id}" href="#pa2${type}${id}" y="-100" width="1280" height="1280" /><pattern id="lgR${type}${id}" href="#bdy${type}${id}" patternTransform="translate(-${
        type == 0 ? 388 : type == 1 ? 536 : 436
      } -700)" /><pattern id="lgL${type}${id}" href="#bdy${type}${id}" patternTransform="translate(-${
        type == 0 ? 512 : type == 1 ? 660 : 560
      } -700)" /><pattern id="wgR${type}${id}" href="#pa3${type}${id}" patternTransform="translate(-${
        type == 0 ? 640 : 280
      } -100)" /><pattern id="wgL${type}${id}" href="#pa3${type}${id}" patternTransform="translate(-${
        type == 0 ? 480 : 500
      } -300)" /><pattern id="lsR${type}${id}" href="#hed${type}${id}" patternTransform="translate(-${
        type == 0 ? 824 : type == 1 ? 744 : 608
      } -268) scale(${
        type == 0 ? '-' : ''
      }1 1)" /><pattern id="lsL${type}${id}" href="#hed${type}${id}" patternTransform="translate(-${
        type == 0 ? 544 : type == 1 ? 844 : 720
      } -268)" />`
    )
  }
}

function getChartParts({
  titles,
  colors,
  datas,
  tags,
  height,
  stroke,
  strokeWidth,
}: {
  titles: string[]
  colors: string[]
  datas: ([number, number] | undefined)[]
  height: string
  stroke: string
  strokeWidth: string
  tags?: string[]
}) {
  return datas.map(
    (data, i) => {
      if (!data) return null

      const view = `${0 + data[0] * 1080} 0 ${1080 * (data[1] - data[0])} 256`
      const width = `${100 * (data[1] - data[0])}%`
      return (
        <React.Fragment key={titles[i]}>
          <ChartSVG viewBox={view} xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
            <title>{tags?.[i] ? tags[i] : titles[i]}</title>
            <animate offset="1" dur="2s" attributeName="opacity" from="0" to="1" />
            <path stroke={stroke} strokeWidth={strokeWidth} fill={colors[i + 1]} d={feather} />
            <rect x="1084" y="0" width="1080" height="256" fill={colors[0]}>
              <animate dur="1.8s" attributeName="x" from="0" to="1084" />
            </rect>
          </ChartSVG>
        </React.Fragment>
      )
    },
    [colors, datas]
  )
}

function CloudImages(id?: number, color?: string) {
  return !id || id == 0
    ? `
     <svg viewBox="0 24 460 200" xmlns="http://www.w3.org/2000/svg" stroke-linejoin="round" stroke-linecap="round" stroke="black" stroke-width=".6">
	   <path fill="${
       color ? color : '#eee'
     }" d="M28 164 Q40 96 96 96 Q116 60 152 56 Q192 20 232 56 Q256 48 272 68 Q296 68 316 96 Q368 104 396 148 Q460 200 420 208 Q344 220 268 208 Q144 224 80 212 Q-40 228 28 164Z" />
	   <path fill="none" stroke="#0002" stroke-width="2"  d="M29 162 q40 -12 80 4 M97 94 q40 -4 72 28 M395 146 q-40 -16 -148 16 M315 94 q-16 -16 -68 16" />
       <path fill="#0002" stroke="none" d="M396 148 Q460 200 420 208 Q344 220 268 208 Q144 224 80 212 Q-40 228 28 164 Q220 228 396 148" />
     </svg>
  `
    : `
      <svg viewBox="0 24 576 192" xmlns="http://www.w3.org/2000/svg" stroke-linejoin="round" stroke-linecap="round" stroke="black" stroke-width=".6">
	    <path fill="${
        color ? color : '#eee'
      }" d="M20 164 Q52 116 116 116 Q172 88 212 96 Q240 72 280 76 Q328 44 380 80 Q468 92 500 136 Q568 144 568 168 Q560 200 500 208 Q344 220 268 208 Q144 224 80 212 Q-28 228 20 164Z" />
	    <path fill="none" stroke-width=".3"  d="M212 95 q40 -4 80 24 M116 116 q40 -4 72 28 M500 136 q-24 -8 -88 16 M380 80 q-24 -8 -60 16" />
        <path fill="#0002" stroke="none" d="M568 168 Q560 200 500 208 Q344 220 268 208 Q144 224 80 212 Q-28 228 20 164 Q220 200 568 168" />
      </svg>
  `
}

export function SunImage(delay?: string, color?: string) {
  return `
        <svg viewBox="-448 6 948 280" xmlns="http://www.w3.org/2000/svg">
            <defs>
<radialGradient id="rgsun" cx="50%" cy="50%" >
        <stop offset="65%" stop-color="#f8f818" />
        <stop offset="100%" stop-color="#f8c818" />
    </radialGradient>
  </defs>
  <g >
  <path fill="url(#rgsun)" stroke="black" stroke-width="0.5" d="M112 28 q4 -8 0 -20 q20 4 20 20 q8 4 16 -16 q8 8 12 26 q4 -4 20 -8 q-4 12 0 24 q4 -4 28 -4 q-4 4 -18 24 q4 4 20 4 q-4 4 -12 24 q4 4 20 8 q-4 4 -20 16 q4 8 24 16 q-4 4 -36 12 q8 8 12 28 q-8 -16 -44 -4 q-8 8 4 32 q-32 -16 -32 -20 q-8 8 -4 24 q-24 -12 -16 -28 q-8 16 -22 28 q4 -12 -16 -32 q-4 0 -20 12 q-8 -12 -8 -32 q-12 0 -28 -4 q8 -12 12 -12 q-12 -8 -20 -12 q12 0 20 -24 q-8 0 -16 -28 q12 0 28 -12 q4 0 -8 -32 q16 0 28 8 q8 0 8 -20 q8 0 16 4 q8 0 12 -24 q0 0 20 22" />

	</g>
	<g strokeLinejoin="round" strokeLinecap="round" stroke="black" strokeWidth="1">
	<g transform="translate(-276,-6) scale(0.78)">
        <g transform="translate(0)">
		    <g transform="translate(544,68)">
                <path fill="#f8f818" d="M-28 28 Q40 4 84 28 Q88 32 98 48 Q116 56 92 76 Q108 100 80 104 Q64 112 40 112 Q24 112 12 116" />
                <path fill="none" stroke-width="1.2" d="M98 48 Q60 38 36 38 Q0 36 -24 56 M80 104 Q64 104 36 102 Q0 96 -16 84" />
	            <path id="glbwL" fill="#f79717" d="M-24 68 Q0 50 28 48 Q88 48 104 64 Q104 84 84 96 Q32 96 28 92 Q4 88 -24 68Z" />
	            <clipPath id="cpiwL">
                  <use href="#glbwL" />
                </clipPath>
	            <g clip-path="url(#cpiwL)">
	              <g>
	                <path fill="#111" stroke="none" d="M46 40 c24 0 32 12 30 28 c0 20 -16 32 -32 32 c-20 0 -32 -16 -32 -28 c0 -12 16 -32 34 -32Z" />
	                <path fill="#fff" stroke="none" d="M52 56 q-8 0 -6 6 q0 8 6 6 q8 0 6 -6 q0 -8 -6 -6 M40 76 q-6 0 -4 4 q0 6 4 4 q6 0 4 -4 q0 -6 -4 -4 " />
                  </g>
	            </g>
	            <path fill="#f8f818" id="lashsunR" stroke-width="1" clip-path="url(#cpiwL)" d="${lash21}" />
                <path2 fill="#0002"  d="M96 92 Q100 100 80 104 Q64 112 40 112 Q24 112 0 120 Q-24 80 -24 60 Q56 96 104 64 Q104 80 96 92Z" />
		    </g>
            <g transform="translate(456,68) scale(-1 1)">
                <path fill="#f8f818" d="M-28 32 Q40 0 84 28 Q88 32 98 48 Q120 56 92 76 Q108 100 80 104 Q64 112 40 112 Q24 112 12 116" />
                <path fill="none" stroke-width="1.2" d="M98 48 Q80 36 36 36 Q0 36 -24 52 M80 104 Q64 104 36 100 Q0 96 -16 80" />
	            <path id="glbwR" fill="#f79717" d="M-24 60 Q0 52 28 44 Q56 40 108 56 Q108 84 84 92 Q32 96 28 92 Q0 88 -24 60Z" />
	            <clipPath id="cpiwR">
                  <use href="#glbwR" />
                </clipPath>
	            <g clip-path="url(#cpiwR)">
	              <g>
	                <path fill="#111" stroke="none" d="M46 40 c28 0 34 16 32 32 c0 12 -17 28 -32 28 c-24 0 -34 -16 -32 -28 c-4 -20 16 -34 32 -32Z" />
	                <path fill="#fff" stroke="none" d="M44 56 q-8 0 -6 6 q0 8 6 6 q8 0 6 -6 q0 -8 -6 -6 M56 76 q-6 0 -4 4 q0 6 4 4 q6 0 4 -4 q0 -6 -4 -4 " />
                  </g>
	            </g>
	            <path fill="#f8f818" id="lashsunL" stroke-width="1" clip-path="url(#cpiwR)" d="${lash21}" />
                <path2 fill="#0002"  d="M96 92 Q100 100 80 104 Q64 112 40 112 Q24 112 0 120 Q-24 80 -24 60 Q56 96 108 56 Q108 80 96 92Z" />
            </g>
        </g>
      </g>
	  <g transform="translate(-240 80) scale(0.8)" stroke-width=".6">
	  <path fill="${
      color ? color : '#eee'
    }" d="M28 164 Q40 96 96 96 Q116 60 152 56 Q192 20 232 56 Q256 48 272 68 Q296 68 316 96 Q368 104 396 148 Q460 200 420 208 Q344 220 268 208 Q144 224 80 212 Q-40 228 28 164Z" />
	   <path fill="none" stroke-width=".3"  d="M28 164 q40 -12 80 4 M96 96 q40 -4 72 28 M396 148 q-40 -16 -148 16 M316 96 q-16 -16 -68 16" />
       <path fill="#0002" stroke="none" d="M396 148 Q460 200 420 208 Q344 220 268 208 Q144 224 80 212 Q-40 228 28 164 Q220 228 396 148" />
	 <g transform="translate(300 0) scale(1.1)">
	    <path fill="${
        color ? color : '#eee'
      }" d="M20 164 Q52 116 116 116 Q172 88 212 96 Q240 72 280 76 Q328 44 380 80 Q468 92 500 136 Q568 144 568 168 Q560 200 500 208 Q344 220 268 208 Q144 224 80 212 Q-28 228 20 164Z" />
	    <path fill="none" stroke-width=".3"  d="M212 95 q40 -4 80 24 M116 116 q40 -4 72 28 M500 136 q-24 -8 -88 16 M380 80 q-24 -8 -60 16" />
        <path fill="#0002" stroke="none" d="M568 168 Q560 200 500 208 Q344 220 268 208 Q144 224 80 212 Q-28 228 20 164 Q220 200 568 168" />
      </g>
	  </g>
        
      </g>
      ${
        delay
          ? `<animate
          id="aniw1${delay}"
          href="#lashsunL"
          attributeName="d"
          values="${lash21};${lash22};${lash21}"
          dur="500ms"
          begin="${delay};aniw1${delay}.end+30s" />
        <animate
          id="aniw2${delay}"
          href="#lashsunR"
          attributeName="d"
          values="${lash21};${lash22};${lash21}"
          dur="500ms"
          begin="${delay};aniw2${delay}.end+30s" />`
          : null
      }
        </svg>
      `
}

export function MoonImage(delay?: string, color?: string) {
  return `
        <svg viewBox="-448 6 948 280" xmlns="http://www.w3.org/2000/svg">
            <defs>
  <radialGradient id="rgcrat" >
        <stop offset="55%" stop-color="#f8f898" />
        <stop offset="70%" stop-color="#a96" />
        <stop offset="100%" stop-color="#f8f898" />
    </radialGradient>
	<radialGradient id="rgcrat2" cx="48%" cy="50%" >
        <stop offset="94%" stop-color="#f8f898" />
        <stop offset="100%" stop-color="#ba7" />
    </radialGradient>
	<circle id="crat" r="20" fill="url(#rgcrat)"/>
	
  </defs>
  <g transform="translate(-8,6) scale(1.12)">
  <g>
 
  <path fill="#f8f898" stroke="black" stroke-width="0.5" d="M108 12 c68 0 84 44 80 76 c0 60 -52 84 -84 84 c-60 0 -84 -52 -84 -76 c0 -44 52 -84 88 -84Z" />
	<path fill="url(#rgcrat2)" stroke="black" stroke-width="0.5" d="M108 12 q4 0 8 -4 q8 4 12 0 q4 4 8 8 M186 64 q4 4 12 8 q-4 8 0 12 q-4 8 -12 12 M164 144 q4 4 4 20 q-8 -4 -24 12 q-4 -8 -20 -8 M28 120 q-4 4 -4 16 q4 0 16 16 q4 0 12 0 M32 60 q4 -8 4 -32 q8 4 16 -4 q4 4 12 8" />

	<use href="#crat" transform="translate(92 36) scale(1 .6)" />
	<use href="#crat" transform="translate(108 160) scale(.8 .4)" />
	<use href="#crat" transform="translate(156 112) scale(.6 .8)" />
	<use href="#crat" transform="translate(36 76) scale(.3 .5)" />
	<use href="#crat" transform="translate(56 124) scale(.6 .8)" />
	<use href="#crat" transform="translate(104 108) scale(1.2 1.2)" />
	<use href="#crat" transform="translate(148 56) scale(.7 .8)" />
	</g>
	<g strokeLinejoin="round" stroke-linecap="round" stroke="black" stroke-width="1">
        <g transform="translate(-236,-10) scale(0.69)">
		    <g transform="translate(544,68)">
                <path fill="#f8f898" d="M-28 28 Q40 4 84 28 Q88 32 98 48 Q116 56 92 76 Q108 100 80 104 Q64 112 40 112 Q24 112 12 116" />
                <path fill="none" stroke-width="1.2" d="M98 48 Q60 38 36 38 Q0 36 -24 56 M80 104 Q64 104 36 102 Q0 96 -16 84" />
	            <path id="glbwL" fill="#f79717" d="M-24 68 Q0 50 28 48 Q88 48 104 64 Q104 84 84 96 Q32 96 28 92 Q4 88 -24 68Z" />
	            <clipPath id="cpiwL">
                  <use href="#glbwL" />
                </clipPath>
	            <g clip-path="url(#cpiwL)">
	              <g>
	                <path fill="#111" stroke="none" d="M46 40 c24 0 32 12 30 28 c0 20 -16 32 -32 32 c-20 0 -32 -16 -32 -28 c0 -12 16 -32 34 -32Z" />
	                <path fill="#fff" stroke="none" d="M52 56 q-8 0 -6 6 q0 8 6 6 q8 0 6 -6 q0 -8 -6 -6 M40 76 q-6 0 -4 4 q0 6 4 4 q6 0 4 -4 q0 -6 -4 -4 " />
                  </g>
	            </g>
	            <path fill="#f8f898" id="lashmoonR" stroke-width="1" clip-path="url(#cpiwL)" d="${lash21}" />
                <path2 fill="#0002"  d="M96 92 Q100 100 80 104 Q64 112 40 112 Q24 112 0 120 Q-24 80 -24 60 Q56 96 104 64 Q104 80 96 92Z" />
		    </g>
            <g transform="translate(456,68) scale(-1 1)">
                <path fill="#f8f898" d="M-28 32 Q40 0 84 28 Q88 32 98 48 Q120 56 92 76 Q108 100 80 104 Q64 112 40 112 Q24 112 12 116" />
                <path fill="none" stroke-width="1.2" d="M98 48 Q80 36 36 36 Q0 36 -24 52 M80 104 Q64 104 36 100 Q0 96 -16 80" />
	            <path id="glbwR" fill="#f79717" d="M-24 60 Q0 52 28 44 Q56 40 108 56 Q108 84 84 92 Q32 96 28 92 Q0 88 -24 60Z" />
	            <clipPath id="cpiwR">
                  <use href="#glbwR" />
                </clipPath>
	            <g clip-path="url(#cpiwR)">
	              <g>
	                <path fill="#111" stroke="none" d="M46 40 c28 0 34 16 32 32 c0 12 -17 28 -32 28 c-24 0 -34 -16 -32 -28 c-4 -20 16 -34 32 -32Z" />
	                <path fill="#fff" stroke="none" d="M44 56 q-8 0 -6 6 q0 8 6 6 q8 0 6 -6 q0 -8 -6 -6 M56 76 q-6 0 -4 4 q0 6 4 4 q6 0 4 -4 q0 -6 -4 -4 " />
                  </g>
	            </g>
	            <path fill="#f8f898" id="lashmoonL" stroke-width="1" clip-path="url(#cpiwR)" d="${lash21}" />
                <path2 fill="#0002"  d="M96 92 Q100 100 80 104 Q64 112 40 112 Q24 112 0 120 Q-24 80 -24 60 Q56 96 108 56 Q108 80 96 92Z" />
            </g>
			</g>
      </g>
	  </g>
			<g transform="translate(-240 80) scale(0.8)" stroke="black"  stroke-width=".6">
	  <path fill="${
      color ? color : '#eee'
    }" d="M28 164 Q40 96 96 96 Q116 60 152 56 Q192 20 232 56 Q256 48 272 68 Q296 68 316 96 Q368 104 396 148 Q460 200 420 208 Q344 220 268 208 Q144 224 80 212 Q-40 228 28 164Z" />
	   <path fill="none" stroke-width=".3"  d="M28 164 q40 -12 80 4 M96 96 q40 -4 72 28 M396 148 q-40 -16 -148 16 M316 96 q-16 -16 -68 16" />
       <path fill="#0002" stroke="none" d="M396 148 Q460 200 420 208 Q344 220 268 208 Q144 224 80 212 Q-40 228 28 164 Q220 228 396 148" />
	 <g transform="translate(300 0) scale(1.1)" >
	    <path fill="${
        color ? color : '#eee'
      }" d="M20 164 Q52 116 116 116 Q172 88 212 96 Q240 72 280 76 Q328 44 380 80 Q468 92 500 136 Q568 144 568 168 Q560 200 500 208 Q344 220 268 208 Q144 224 80 212 Q-28 228 20 164Z" />
	    <path fill="none" stroke-width=".3"  d="M212 95 q40 -4 80 24 M116 116 q40 -4 72 28 M500 136 q-24 -8 -88 16 M380 80 q-24 -8 -60 16" />
        <path fill="#0002" stroke="none" d="M568 168 Q560 200 500 208 Q344 220 268 208 Q144 224 80 212 Q-28 228 20 164 Q220 200 568 168" />
      </g>
        </g>
    
      ${
        delay
          ? `<animate
          id="aniw1${delay}"
          href="#lashmoonL"
          attributeName="d"
          values="${lash21};${lash22};${lash21}"
          dur="500ms"
          begin="${delay};aniw1${delay}.end+30s" />
        <animate
          id="aniw2${delay}"
          href="#lashmoonR"
          attributeName="d"
          values="${lash21};${lash22};${lash21}"
          dur="500ms"
          begin="${delay};aniw2${delay}.end+30s" />`
          : null
      }
        </svg>
      `
}

/**
 * Takes in custom size and stroke for circle color, default to primary color as fill,
 * need ...rest for layered styles on top
 */
function SDove(type: number): string {
  //delay2 = delay2 ?? delay;
  //const dpi = window.devicePixelRatio || 1;
  const types = [
    [
      lash1,
      '-8',
      'M58 120 Q54 190 58 214    Q68 214 78 218 Q88 216 98 220 Q98 220 98 220 Q88 216 78 218 Q68 214 58 214     Q68 214 78 218 Q88 216 98 220  Q100 223 98 227 Q83 230 76 228 Q65 230 60 228    Q72 234 72 238 Q82 245 80 248 Q76 256 65 248 Q56 240 56 240 Q46 236 42 229    Q28 232 25 231 Q16 234 10 233 Q08 230 10 227 Q14 222 25 222 Q30 218 42 216    Q46 190 42 120Z  M98 220 l0 0 l0 0 l0 0Z  M95 222 l10 1 l-10 1 l0 -2Z   M76 247 l6 8 l-8 -7 l2 -1Z   M13 228 l-10 4 l10 -2 0 -2Z',
      '0 50 0',
      '0',
      shadow1R,
    ],
    [
      'M52 4 C60 4 60 12 56 12 Q12 24 8 64 Q8 100 56 112 C56 112 56 120 52 120 Q0 104 0 64 Q0 20 52 4Z M-12 48 Q-8 12 20 4 Q36 -4 52 4 Q0 20 0 64 Q0 104 52 120 Q52 120 52 120 Q16 120 -4 96',
      '12',
      'M0 128 Q56 198 58 214    Q68 214 78 218 Q88 216 98 220 Q98 220 98 220 Q88 216 78 218 Q68 214 58 214     Q68 214 78 218 Q88 216 98 220  Q100 223 98 227 Q83 230 76 228 Q65 230 60 228    Q72 234 72 238 Q82 245 80 248 Q76 256 65 248 Q56 240 56 240 Q46 236 42 229    Q28 232 25 231 Q16 234 10 233 Q08 230 10 227 Q14 222 25 222 Q30 218 42 216    Q46 200 -20 128Z  M98 220 l0 0 l0 0 l0 0Z  M95 222 l10 1 l-10 1 l0 -2Z   M76 247 l6 8 l-8 -7 l2 -1Z   M13 228 l-10 4 l10 -2 0 -2Z',
      '30 50 0',
      '-20',
      'M60 112 C60 112 60 112 60 112 Q28 220 0 92 Q0 80 6 62 Q56 120 106 62 c0 30 -25 50 -50 50 c-30 0 -50 -25 -50 -50 Q8 100 68 112Z',
    ],
  ]

  return `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 1080">
<defs>
 <linearGradient id="lgHead" x1="0%" y1="0%" x2="10%" y2="100%" gradientTransform="rotate(20 0.8 0.5)">
      <stop offset="74%" stop-color="#A8A8A8" stop-opacity="1" />
      <stop offset="90%" stop-color="#57C757" stop-opacity="1" />
      <stop offset="100%" stop-color="#9070C7" stop-opacity="1" />
    </linearGradient>
	<linearGradient id="lgBody" x1="0%" y1="0%" x2="-15%" y2="100%">
      <stop offset="0%" stop-color="#9070C7" stop-opacity="1" />
      <stop offset="10%" stop-color="#888888" stop-opacity="1" />
    </linearGradient>
	<linearGradient id="Sbek" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" stop-color="#FC3" />
	    <stop offset="30%" stop-color="#FC3" />
        <stop offset="100%" stop-color="#B81" />
    </linearGradient>
	<linearGradient id="nos" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" stop-color="#bbb" stop-opacity="1" />
        <stop offset="0%" stop-color="#ddd" stop-opacity="1" />
	    <stop offset="30%" stop-color="#ddd" stop-opacity="1" />
        <stop offset="100%" stop-color="#bbb" stop-opacity="1" />
    </linearGradient>
  <path id="tail" transform="translate(0,-100)" fill="#181818"  d="M340 580 Q180 440 152 436  Q148 436 144 440  Q136 436 132 440   Q108 432 116 452   Q280 672 340 700       M144 440 Q260 560 280 580   M284 612 Q240 540 132 440"/>
    <path id="body" fill="url(#lgBody)"  d="M648 395 Q480 376 340 480   Q260 540 340 600   Q708 920 840 455" />
	<path id="head" fill="url(#lgHead)" d="M840 456 Q864 356 900 256 Q920 164 844 140 Q748 120 716 228 Q664 360 648 396"/>
	
	<g id="SeyeRight${type}" transform="translate(84 156) rotate(39 0 48)">
	    <path id="globeRight" fill="#f79717" d="M56 12 c30 0 50 25 50 50 c0 30 -25 50 -50 50 c-30 0 -50 -25 -50 -50 c0 -30 25 -50 50 -50" />
		<path id="irisRight" fill="#111" stroke="none" d="M56 30 c20 0 34 17 34 34 c0 20 -17 34 -34 34 c-20 0 -34 -17 -34 -34 c0 -20 17 -34 34 -34" />
		<path id="mirrorRight" fill="#fff" stroke="none" d="M60 48 c4 0 6 3 6 6 c0 4 -3 6 -6 6 c-4 0 -6 -3 -6 -6 c0 -4 3 -6 6 -6 M48 72 c2.8 0 4 2 4 4 c0 2.8 -2 4 -4 4 c-2.8 0 -4 -2 -4 -4 c0 -2.8 2 -4 4 -4" />
        <path id="SlashRight${type}" fill="#A7A7A7" d="${types[type][0]}" />
		<path fill="#0002" stroke="none" d="${types[type][5]}" />
	</g>
    <g id="Sbeak" transform="translate(28 172) rotate(39 0 40)">
        <path fill="#111" d="M8 32 Q24 52 24 52 Q12 72 12 72 Q4 52 8 32Z" />
	        <path fill="#fbb" d="M0 44 Q28 52 32 56 Q28 64 0 60 Q-4 52 0 44Z M8 44 Q4 44 52 52" />
		    <path id="mo" fill="#ffe" stroke-width="0.5" d="M92 52 Q56 72 0 56 Q-4 54 0 52 Q36 60 92 52 Q 96 52 92 52Z M-4 53 Q40 65 94 52 Q40 65 -4 53Z M12 48 Q24 60 20 68 M48 48 Q44 56 36 68 M52 48 Q56 56 56 68 M72 48 Q76 56 80 60" />  
		    <path fill="#8c7c88" d="M8 32 Q-52 48 -4 72 Q28 88 108 52 Q36 68 -4 52 Q-4 52 -4 52 Q36 68 108 52 Q36 24 8 32Z M27 36 c4 4 4 -4 0 0Z M24 32 c4 4 4 -4 0 0Z" />
	        <path fill="#333"  d="M-8 28 C4 20 24 20 28 32 C24 48 -56 60 -8 28" />
		    <path  stroke-width="0.6" fill="#A8A8A8" d="M-32 50 Q-20 42 -16 50 Q-20 50 -22 56 Q-16 52 -10 56 Q-16 60 -16 62 Q-8 58 -4 62 Q-8 64 -10 69 Q-4 64 2 68 Q-6 72 -6 78" />
	        <path stroke="none" fill="#0001" d="M0 54 Q-52 32 4 132 Q8 132 8 76 Q28 84 108 52 Q60 64 0 54Z" />
		</g>
	<g id="wing" transform="rotate(-0)">
	    <path fill="#181818" d="M325 80 Q110 80 80 92 Q60 88 40 100 Q16 96 0 110 Q-20 106 -40 120 Q-80 168 280 224   M80 92 Q20 140 200 152 M40 100 Q0 160 232 168 M0 110 Q-28 160 240 188" />
	    <path fill="#666" d="M440 -28 Q260 -60 120 52 Q80 84 132 88 Q120 116 156 116 Q152 144 188 144 Q190 176 212 182 Q205 200 224 220 Q320 280 460 220 Q600 160 528 40 Q500 -16 440 -28 Z" />
        <use href="#fetr" transform="translate(380,200) rotate(-10 -12 -40)" />
	        <use href="#fetr" transform="translate(360,68) rotate(10 -12 -40)" />
	        <use href="#fetr" transform="translate(220,120)" />
		    
		<path fill="rgb(0,0,0,0.4)" stroke="none"  d="M224 220 Q224 200 236 188 Q230 160 260 148 Q240 120 276 92 Q260 60 284 40 Q256 16 292 -28 L244 -16 Q220 24 240 40 Q224 64 244 88 Q220 124 232 144 Q216 156 212 182 Q205 200 224 220 Z" />
        <path fill="rgb(0,0,0,0.5)" stroke="none" d="M212 182 Q200 164 212 140 Q200 124 212 96 Q200 80 208 52 Q180 36 192 4 L140 36 Q132 36 168 52 Q156 84 188 100 Q172 124 188 144 Q190 176 212 182 Z" />
	<path opacity=".1" stroke="none" d="M224 220 Q320 280 460 220 Q600 160 528 40 Q628 196 224 220Z" />
	</g>
	<path id="SfootRight${type}" transform="translate(0,${types[type][4]})" d="${types[type][2]}" />
    <path id="Sleg${type}" transform="rotate(${types[type][3]})" d="M-8 0 Q-20 48 24 84 Q20 108 40 128 Q50 136 60 128 Q80 108 76 84 Q120 48 108 0" />
	<g id="fetr" transform="rotate(0 12 12)">
	    <path fill="none" stroke-width="0.5" transform="rotate(150 12 12)"  d="M-32 50 Q-20 44 -16 50 Q-20 50 -22 56 Q-16 52 -10 56 Q-16 60 -16 62 Q-8 58 -4 62 Q-8 64 -10 69 Q-4 64 2 68 Q-6 72 -6 78" />
	    <path opacity=".1" transform="rotate(150 12 12)" stroke="none" d="M-32 50 Q-20 44 -16 50 Q-20 50 -22 56 Q-16 52 -10 56 Q-16 60 -16 62 Q-8 58 -4 62 Q-8 64 -10 69 Q-4 64 2 68 Q-6 72 -6 78 Q-6 72 6 64 Q-4 62 -10 69 Q-8 64 0 58 Q-8 55 -16 62 Q-16 60 -7 52 Q-18 50 -22 56 Q-20 50 -12 48 Q-20 41 -32 50Z" />
	</g>
	</defs>
    <g id="Sdove${type}" transform="translate(-96,${types[type][1]})"  stroke-linejoin="round" stroke-linecap="round" stroke="black" stroke-width="1">
	<g transform="translate(596,680) scale(0.95)">
			<use href="#SfootRight${type}" fill="#fbb" />
			<use href="#SfootRight${type}" opacity=".1" />
            <use href="#Sleg${type}" fill="#888888" />
			<use href="#Sleg${type}" opacity=".1" />
		</g>
		<g transform="rotate(15 600 700)" >
		<use href="#tail" />
        <use href="#body" />
		<use href="#head" />
		<use href="#fetr" transform="translate(784,420)"   />
		<use href="#fetr" transform="translate(724,352)" />
		<path fill="#A8A8A8" transform="translate(72,100)" stroke-width="0.6" d="M730 48 q4 -12 18 -20 q-4 8 -2 12 q4 -14 18 -16 q-6 4 -4 14 q8 -18 20 -18 q-8 12 -4 18 q4 -18 20 -22 q-8 16 -4 36" />
		
	<path stroke="none" opacity=".1" d="M340 480 Q260 540 340 600 Q716 920 840 456 Q864 356 900 256 Q880 320 800 456 Q740 640 340 592 Z" />
		
			<use href="#Sbeak" transform="translate(780,140) rotate(-20, 0,40)" />
            <use href="#SeyeRight${type}" transform="translate(708,52) rotate(-20,0,48) scale(1.05)" />
			</g>
		<g transform="translate(540,680) scale(1.05)">
			<use href="#SfootRight${type}" fill="#fbb" />
			<use href="#SfootRight${type}" opacity=".1" />
		    <use href="#Sleg${type}" fill="#888888" />
			<use href="#fetr" transform="translate(48,76) rotate(-20 -12 -40)" />
			<use href="#Sleg${type}" opacity=".1" />
		</g>
		<use href="#wing" transform="translate(220,404) rotate(15 300 300)"/>
		</g> 
	</svg>
  `
}

/**
 * Takes in custom size and stroke for circle color, default to primary color as fill,
 * need ...rest for layered styles on top
 */
function TDove(type: number): string {
  //delay2 = delay2 ?? delay;
  //const dpi = window.devicePixelRatio || 1;
  const types = [
    [
      'M848 409 Q848 260 776 148 Q716 12 620 36 Q512 60 568 152 Q640 260 640 369',
      'M640 367 Q500 376 320 492   Q260 520 340 592    Q796 920 848 407',
      'M320 592 Q240 520 228 436  Q220 400 200 420  Q188 400 174 416   Q140 392 136 412   Q180 620 340 692  M200 420 Q228 540 280 580   M264 612 Q228 540 174 424',
      '0 0',
      '',
      '',
      'rotate(6 0.7 0)',
    ],
    [
      'M840 440 Q864 356 900 256 Q920 164 844 140 Q748 120 716 228 Q664 360 660 380',
      'M660 379 Q480 376 320 492   Q260 540 340 592   Q788 896 840 439',
      'M320 592 Q180 440 152 436  Q148 436 144 440  Q136 436 132 440   Q108 432 116 452   Q280 672 340 704       M144 440 Q260 560 280 580   M284 612 Q240 540 132 440',
      '0 -8',
      'translate(84 156) rotate(35 0 40)',
      'translate(28 180) rotate(35 0 40)',
      'rotate(20 0.8 0.5)',
    ],
    [
      'M800 540 Q836 580 828 620 Q788 800 924 788 Q996 780 996 648 Q1000 460 787 368',
      'M788 368 Q540 280 308 492   Q264 540 320 592    Q672 876 804 540',
      'M308 592 Q108 776 100 808  Q100 820 124 808  Q124 820 140 812   Q140 824 152 816   Q260 752 320 696       M124 808 Q260 708 280 672   M280 712 Q240 724 140 812',
      '0 -16',
      'translate(12 652) rotate(140 0 40)',
      'translate(-108 512) rotate(140 0 40)',
      'rotate(160 0.5 0.6)',
    ],
    [
      'M780 556 Q848 640 800 780 Q760 896 876 904 Q952 908 980 780 Q1024 528 836 408',
      'M836 408 Q548 220 296 492   Q260 520 340 592    Q632 876 784 556',
      'M296 592  Q148 736 180 888  Q188 900 204 888  Q216 900 232 892   Q248 904 252 896   Q260 752 340 692       M204 888 Q268 708 252 692   M280 712 Q248 764 232 892',
      '0 -32',
      'translate(-68 732) rotate(180 0 40)',
      'translate(-148 564) rotate(180 0 40)',
      'rotate(180 0.5 0.61)',
    ],
  ]

  return `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="60 56 940 940">
      <defs>
        <linearGradient id="lgHead" x1="0%" y1="0%" x2="10%" y2="100%" gradientTransform="${types[type][6]}">
          <stop offset="60%" stop-color="#A7A7A7" stop-opacity="1" />
          <stop offset="85%" stop-color="#57C757" stop-opacity="1" />
          <stop offset="100%" stop-color="#9070C7" stop-opacity="1" />
        </linearGradient>
	    <linearGradient id="lgBody" x1="70%" y1="12%" x2="0%" y2="100%" gradientTransform="rotate(20 0.3 0.5)">
          <stop offset="0%" stop-color="#9070C7" stop-opacity="1" />
          <stop offset="10%" stop-color="#878787" stop-opacity="1" />
        </linearGradient>
	    <radialGradient id="rgTail" cx="200%" cy="40%" r="1.5">
          <stop offset="78%" stop-color="#878787" stop-opacity="1" />
          <stop offset="85%" stop-color="#181818" stop-opacity="1" />
        </radialGradient>
        <path id="tail" fill="url(#rgTail)"  d="${types[type][2]}" />
        <path id="body" fill="url(#lgBody)"  d="${types[type][1]}" />
	    <path id="head" fill="url(#lgHead)" d="${types[type][0]}" />
	    <g id="eyeRight" transform="${types[type][4]}">
	      <path id="globeRight" fill="#f79717" d="${globe}" />
	      <path id="irisRight" fill="#111" stroke="none" d="M56 30 c20 0 34 17 34 34 c0 20 -17 34 -34 34 c-20 0 -34 -17 -34 -34 c0 -20 17 -34 34 -34" />
	      <path id="mirrorRight" fill="#fff" stroke="none" d="M60 48 c4 0 6 3 6 6 c0 4 -3 6 -6 6 c-4 0 -6 -3 -6 -6 c0 -4 3 -6 6 -6 M48 72 c2.8 0 4 2 4 4 c0 2.8 -2 4 -4 4 c-2.8 0 -4 -2 -4 -4 c0 -2.8 2 -4 4 -4" />
          <path id="lashRight" fill="#A7A7A7" d="${lash1}" />
	    </g>
        <g id="beak" transform="${types[type][5]}">
          <path fill="#111" d="M8 32 Q24 52 24 52 Q12 72 12 72 Q4 52 8 32Z" />
	    <path fill="#fbb" d="M8 44 Q4 44 52 52 Q8 60 8 60 Q4 52 8 44Z M8 44 Q4 44 52 52" />
	    <path fill="#fb0" d="M0 68 Q16 84 104 52 Q40 60 -4 52" />
	    <path fill="#fb0" d="M4 28 Q40 27 104 52 Q40 60 -4 52 M32 36 C36 40 28 40 32 36 M24 32 C28 36 20 36 24 32" />
        <path fill="#ddd"  d="M4 24 C16 20 24 20 28 32 C40 56 -20 60 4 24" />
	</g>
	    <g id="wing" transform="translate(${types[type][3]})">
	      <path fill="#181818" d="M325 80 Q100 80 60 92 Q40 88 20 100 Q0 96 -20 110 Q-48 106 -60 120 Q-80 168 280 224   M60 92 Q20 140 200 152 M20 100 Q0 160 232 168 M-20 110 Q-28 160 240 188" />
	    <path fill="#686868" d="M440 -28 Q240 -60 88 56 Q68 80 112 88 Q112 116 140 116 Q148 144 180 144 Q190 176 212 182 Q205 200 224 220 Q320 280 460 220 Q600 160 528 40 Q500 -16 440 -28 Z" />
        <path fill="rgb(0,0,0,0.4)" stroke="none"  d="M224 220 Q224 200 236 188 Q230 160 260 148 Q240 120 276 92 Q260 60 284 40 Q256 16 296 -32 L244 -20 Q220 24 240 40 Q224 64 244 88 Q220 124 232 144 Q216 156 212 182 Q205 200 224 220 Z" />
        <path fill="rgb(0,0,0,0.5)" stroke="none" d="M212 182 Q200 164 212 140 Q200 124 212 96 Q200 80 208 52 Q180 36 192 -4 L140 20 Q132 36 168 52 Q156 84 188 100 Q172 124 188 144 Q190 176 212 182 Z" />
	</g>
	    <path id="footRight" fill="#fbb" d="M58 120 Q54 190 58 214    Q68 214 78 218 Q88 216 98 220 Q98 220 98 220 Q88 216 78 218 Q68 214 58 214     Q68 214 78 218 Q88 216 98 220  Q100 223 98 227 Q83 230 76 228 Q65 230 60 228    Q72 234 72 238 Q82 245 80 248 Q76 256 65 248 Q56 240 56 240 Q46 236 42 229    Q28 232 25 231 Q16 234 10 233 Q08 230 10 227 Q14 222 25 222 Q30 218 42 216    Q46 190 42 120Z  M98 220 l0 0 l0 0 l0 0Z  M95 222 l10 1 l-10 1 l0 -2Z   M76 247 l6 8 l-8 -7 l2 -1Z   M13 228 l-10 4 l10 -2 0 -2Z" />
        <path id="footLeft" fill="#fbb" d="M58 120 Q54 190 58 214    Q68 214 78 218 Q88 216 98 220 Q98 220 98 220 Q88 216 78 218 Q68 214 58 214     Q68 214 78 218 Q88 216 98 220  Q100 223 98 227 Q83 230 76 228 Q65 230 60 228    Q72 234 72 238 Q82 245 80 248 Q76 256 65 248 Q56 240 56 240 Q46 236 42 229    Q28 232 25 231 Q16 234 10 233 Q08 230 10 227 Q14 222 25 222 Q30 218 42 216    Q46 190 42 120Z  M98 220 l0 0 l0 0 l0 0Z  M95 222 l10 1 l-10 1 l0 -2Z   M76 247 l6 8 l-8 -7 l2 -1Z   M13 228 l-10 4 l10 -2 0 -2Z" />
	    <path id="legRight" fill="#878787" d="M-12 0 Q-20 48 40 128 Q50 136 60 128 Q120 48 112 0" />
	    <path id="legLeft" fill="#878787" d="M-12 0 Q-20 48 40 128 Q50 136 60 128 Q120 48 112 0" />
      </defs>
	  <g id="dove" transform="translate(-24 40)" stroke-linejoin="round" stroke-linecap="round" stroke="black" stroke-width="1">
	    <g id="legL" transform="translate(592,680) scale(0.95)">
          <use href="#footLeft" />
          <use href="#legLeft" />
	  	</g>
        <use href="#body" />
	  	<use href="#tail" transform="translate(0,-100)"/>
	  	<use href="#head" />
	  	<use href="#beak" transform="translate(772,120) rotate(-20, 0,40)" />
        <use href="#eyeRight" transform="translate(692,40) rotate(-20,0,48) scale(1.05)" />
	  	<g id="legR" transform="translate(536,680) scale(1.05)">
	  	  <use href="#footRight" />
	  	  <use href="#legRight" />
	  	</g>
	  	<use href="#wing" transform="translate(212 480) rotate(-12)"/>
	  </g>
    </svg>
  `
}

/**
 * Takes in custom size and stroke for circle color, default to primary color as fill,
 * need ...rest for layered styles on top
 */
export function TDoveAnimated({
  animate,
  duray = '1s',
  size = 512,
  ...rest
}: {
  animate?: boolean
  duray?: string
  size?: number
}) {
  const dpi = window.devicePixelRatio || 1
  size = size / dpi
  //const { ref, inView } = useInView();
  return (
    <ContainerTrash>
      <Canvtrash1 animate={animate} duray={duray}>
        <Canvas data={TDove(0)} height={size} width={size} />
      </Canvtrash1>
      <Canvtrash2 animate={animate} duray={duray}>
        <Canvas data={TDove(1)} height={size} width={size} />
      </Canvtrash2>
      <Canvtrash3 animate={animate} duray={duray}>
        <Canvas data={TDove(2)} height={size} width={size} />
      </Canvtrash3>
      <Canvtrash4 animate={animate} duray={duray}>
        <Canvas data={TDove(3)} height={size} width={size} />
      </Canvtrash4>
    </ContainerTrash>
  )
}

/**
 * Takes in custom size and stroke for circle color, default to primary color as fill,
 * need ...rest for layered styles on top
 */
export function SDoveAnimated({
  animate,
  duray = '0s',
  size = 512,
  ...rest
}: {
  animate?: boolean
  duray?: string
  size?: number
}) {
  const dpi = window.devicePixelRatio || 1
  size = Math.round(size / dpi)
  //const { ref, inView } = useInView();
  return (
    <Container>
      <div style={{ width: '50%' }}>
        <CoinsSVG viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <clipPath id="cPathLogo">
              <circle cx="256" cy="256" r="224" fill="#74E" stroke="#53C" />
            </clipPath>
            <g id="pepe">
              <circle cx="256" cy="256" r="240" fill="#151" />
              <image
                pointerEvents="none"
                href={PepeLogo}
                x="0"
                y="0"
                width="512"
                height="512"
                clipPath="url(#cPathLogo)"
              />
            </g>
          </defs>
          <g>
            <image pointerEvents="none" href={DogeLogo} x="256" y="0" width="256" height="256" />
            <image pointerEvents="none" href={ShibLogo} x="0" y="0" width="272" height="272" />

            <use href="#pepe" x="144" y="284" transform="scale(0.65)" />
          </g>
        </CoinsSVG>
      </div>
      <Canv1 animate={animate} duray={duray}>
        <Canvas data={SDove(0)} height={size} width={size} widthAsMax={false} />
      </Canv1>
      <Canv2 animate={animate} duray={duray}>
        <Canvas data={SDove(1)} height={size} width={size} widthAsMax={false} />
      </Canv2>
      <ShitSVG animate={animate} delay={duray} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 136 160">
        <g id="shit">
          <ShitPath1 animate={animate} delay={duray} id="back" stroke="#000" fill="#ccc" d={shitPath1} />
          <ShitPath2 animate={animate} delay={duray} id="frnt" stroke="none" fill="#333" d={shitPath2} />
          <image pointerEvents="none" href={Logo} x="44" y="56" width="48" />
        </g>
      </ShitSVG>
    </Container>
  )
}

/**
 * Takes in custom size and stroke for circle color, default to primary color as fill,
 * need ...rest for layered styles on top
 */
export function DDoveAnimated({
  animate,
  duray = '1s',
  size = 512,
  widthAsMax = false,
}: {
  animate?: boolean
  widthAsMax?: boolean
  duray?: string
  size?: number
}) {
  //const { ref, inView } = useInView();
  const dpi = window.devicePixelRatio || 1
  size = Math.round(size / dpi)
  const height = Math.round(size * 0.75)
  return (
    <ContainerDance>
      <CanvDance1 animate={animate} duray={duray}>
        <Canvas data={DoveDTypes(0)} height={height} width={size} widthAsMax={widthAsMax} />
      </CanvDance1>
      <CanvDance2 animate={animate} duray={duray}>
        <Canvas data={DoveDTypes(1)} height={height} width={size} widthAsMax={widthAsMax} />
      </CanvDance2>
      <CanvDance3 animate={animate} duray={duray}>
        <Canvas data={DoveDTypes(2)} height={height} width={size} widthAsMax={widthAsMax} />
      </CanvDance3>
      <CanvDance4 animate={animate} duray={duray}>
        <Canvas data={DoveDTypes(3)} height={height} width={size} widthAsMax={widthAsMax} />
      </CanvDance4>
      <CanvDance5 animate={animate} duray={duray}>
        <Canvas data={DoveDTypes(4)} height={height} width={size} widthAsMax={widthAsMax} />
      </CanvDance5>
      <CanvDance6 animate={animate} duray={duray}>
        <Canvas data={DoveDTypes(5)} height={height} width={size} widthAsMax={widthAsMax} />
      </CanvDance6>
      <CanvDance7 animate={animate} duray={duray}>
        <Canvas data={DoveDTypes(6)} height={height} width={size} widthAsMax={widthAsMax} />
      </CanvDance7>
      <CanvDance8 animate={animate} duray={duray}>
        <Canvas data={DoveDTypes(3)} height={height} width={size} widthAsMax={widthAsMax} />
      </CanvDance8>
    </ContainerDance>
  )
}

/**
 * Takes in custom size and stroke for circle color, default to primary color as fill,
 * need ...rest for layered styles on top
 */
function DoveBigEye({
  animate,
  duray = '2s',
  delay = '0s',
  height = '100%',
  width = '100%',
  ...rest
}: {
  animate?: boolean
  duray?: string
  delay?: string
  height?: string
  width?: string
}) {
  return (
    <DoveSVG viewBox="200 36 680 344" width={width} height={height} {...rest}>
      <defs>
        <linearGradient id="gradientHead" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="64%" stopColor="#A7A7A7" stopOpacity="1" />
          <stop offset="76%" stopColor="#57C757" stopOpacity="1" />
          <stop offset="88%" stopColor="#9070C7" stopOpacity="1" />
          <stop offset="100%" stopColor="#878787" stopOpacity="1" />
        </linearGradient>
        <path
          id="head"
          fill="url(#gradientHead)"
          d="M392 388 Q400 240 410 140 Q420 40 500 40 Q580 40 590 140 Q600 300 608 388"
        />
        <clipPath id="clipCircle">
          <circle cx="500" cy="168" r="220" fill="#74E" stroke="#53C" />
        </clipPath>
        <g id="eyeRight">
          <path id="globeRight" fill="#f79717" d={globe} />
          <path
            id="irisRight"
            fill="#111"
            stroke="none"
            d="M56 30 c20 0 34 17 34 34 c0 20 -17 34 -34 34 c-20 0 -34 -17 -34 -34 c0 -20 17 -34 34 -34"
          />
          <path
            id="mirrorRight"
            fill="#fff"
            stroke="none"
            d="M60 48 c4 0 6 3 6 6 c0 4 -3 6 -6 6 c-4 0 -6 -3 -6 -6 c0 -4 3 -6 6 -6 M48 72 c2.8 0 4 2 4 4 c0 2.8 -2 4 -4 4 c-2.8 0 -4 -2 -4 -4 c0 -2.8 2 -4 4 -4"
          />
          <path id="lashRight" fill="#A7A7A7" d={lash1} />
        </g>
        <g id="token" transform="scale(0.125)">
          <g transform="translate(-250,-250)" opacity="1">
            <circle cx="504" cy="504" r="252" fill="#74E" />
            <g
              transform="translate(-174,223) scale(1.36)"
              strokeLinejoin="round"
              strokeLinecap="round"
              stroke="black"
              strokeWidth="2"
            >
              <use href="#head" clipPath="url(#clipCircle)" />
              <g id="face">
                <use href="#eyeRight" transform="translate(468,68) scale(-1 1)" />
                <use href="#eyeRight" transform="translate(532,68)" />
                <use href="#beak" transform="translate(464,160)" />
              </g>
            </g>
            <circle cx="506" cy="506" r="250" fill="none" stroke="#53C" strokeWidth="12" />
          </g>
        </g>
        <g id={`eyeRightB${delay}`}>
          <g transform="translate(18,64)">
            <Logo1 href={Logo} animate={animate} width="100px" height="100px" x="-16" y="-48" />
          </g>
          <LashPathB animate={animate} id={`lashRightB${delay}`} fill="#A7A7A7" d={lash1} transform="rotate(4)" />
        </g>
        <g id="beak">
          <path fill="#fb0" d="M68 44 Q52 76 36 84 Q20 76 4 44 Q8 76 36 92 Q64 76 68 44Z" />
          <path
            fill="#fb0"
            d="M4 44 Q36 32 68 44 Q52 80 36 88 Q20 80 4 44Z M24 48 C28 52 20 52 24 48 M48 48 C52 52 44 52 48 48"
          />
          <path fill="#ddd" d="M44 24 C80 12 80 68 36 40 C-8 68 -8 12 32 24" />
        </g>
      </defs>
      <g transform="translate(40,40)" strokeLinejoin="round" strokeLinecap="round" stroke="black" strokeWidth="1">
        <use href={`#eyeRightB${delay}`} transform="translate(468,68) scale(-1 1)" />
        <use href={`#eyeRightB${delay}`} transform="translate(532,68)" />
      </g>
    </DoveSVG>
  )
}

export function DoveWink({
  animate,
  delay,
  type = 2,
  id = 0,
  height = '100%',
  width = '100%',
  ...rest
}: {
  animate?: boolean
  type?: number
  id?: number
  delay?: string
  height?: string
  width?: string
}) {
  const defs = getDefs(0, id ?? 0)
  //const dpi = window.devicePixelRatio || 1;
  return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="200 20 680 996">
      <defs>
	  ${defs}
      </defs>
      <g transform="translate(40,40)" strokeLinejoin="round" strokeLinecap="round" stroke="black" strokeWidth="1">
        <g transform="${faceTransforms[type]}">
		    <g transform="translate(544,68)">
                <path class="sl0${id}" d="M-28 28 Q40 4 84 28 Q88 32 98 48 Q116 56 92 76 Q108 100 80 104 Q64 112 40 112 Q24 112 12 116" />
                <path fill="none" stroke-width="1.2" d="M98 48 Q60 38 36 38 Q0 36 -24 56 M80 104 Q64 104 36 102 Q0 96 -16 84" />
	            <path id="glbwL" class="el0${id}" d="M-24 68 Q0 50 28 48 Q88 48 104 64 Q104 84 84 96 Q32 96 28 92 Q4 88 -24 68Z" />
	            <clipPath id="cpiwL">
                  <use href="#glbwL" />
                </clipPath>
	            <g clip-path="url(#cpiwL)">
	              <g>
	                <path fill="#111" stroke="none" d="M46 40 c24 0 32 12 30 28 c0 20 -16 32 -32 32 c-20 0 -32 -16 -32 -28 c0 -12 16 -32 34 -32Z" />
	                <path fill="#fff" stroke="none" d="M52 56 q-8 0 -6 6 q0 8 6 6 q8 0 6 -6 q0 -8 -6 -6 M40 76 q-6 0 -4 4 q0 6 4 4 q6 0 4 -4 q0 -6 -4 -4 " />
                  </g>
	            </g>
	            <path id="lashwL${delay}${id}" class="sl0${id}" stroke-width="1" clip-path="url(#cpiwL)" d="${lash21}" />
                <path2 class="sh"  d="M96 92 Q100 100 80 104 Q64 112 40 112 Q24 112 0 120 Q-24 80 -24 60 Q56 96 104 64 Q104 80 96 92Z" />
		    </g>
            <g transform="translate(456,68) scale(-1 1)">
                <path class="sr0${id}" d="M-28 32 Q40 0 84 28 Q88 32 98 48 Q120 56 92 76 Q108 100 80 104 Q64 112 40 112 Q24 112 12 116" />
                <path fill="none" stroke-width="1.2" d="M98 48 Q80 36 36 36 Q0 36 -24 52 M80 104 Q64 104 36 100 Q0 96 -16 80" />
	            <path id="glbwR" class="er0${id}" d="M-24 60 Q0 52 28 44 Q56 40 108 56 Q108 84 84 92 Q32 96 28 92 Q0 88 -24 60Z" />
	            <clipPath id="cpiwR">
                  <use href="#glbwR" />
                </clipPath>
	            <g clip-path="url(#cpiwR)">
	              <g>
	                <path fill="#111" stroke="none" d="M46 40 c28 0 34 16 32 32 c0 12 -17 28 -32 28 c-24 0 -34 -16 -32 -28 c-4 -20 16 -34 32 -32Z" />
	                <path fill="#fff" stroke="none" d="M44 56 q-8 0 -6 6 q0 8 6 6 q8 0 6 -6 q0 -8 -6 -6 M56 76 q-6 0 -4 4 q0 6 4 4 q6 0 4 -4 q0 -6 -4 -4 " />
                  </g>
	            </g>
	            <path id="lashwR${delay}${id}" class="sr0${id}" stroke-width="1" clip-path="url(#cpiwR)" d="${lash21}" />
                <path2 class="sh"  d="M96 92 Q100 100 80 104 Q64 112 40 112 Q24 112 0 120 Q-24 80 -24 60 Q56 96 108 56 Q108 80 96 92Z" />
            </g>
        </g>
      </g>
      ${
        delay
          ? `<animate
          id="aniw1${delay}"
          href="#lashwL${delay}${id}"
          attributeName="d"
          values="${lash21};${lash22};${lash21}"
          dur="500ms"
          begin="${delay};aniw1${delay}.end+30s" />
        <animate
          id="aniw2${delay}"
          href="#lashwR${delay}${id}"
          attributeName="d"
          values="${lash21};${lash22};${lash21}"
          dur="500ms"
          begin="${delay};aniw2${delay}.end+30s" />`
          : null
      }

    </svg>`
}

/**
 * Takes in custom size and stroke for circle color, default to primary color as fill,
 * need ...rest for layered styles on top
 */
function DoveHeadTypes(type: number, id: number): string {
  //delay2 = delay2 ?? delay;
  //const dpi = window.devicePixelRatio || 1;
  const defs = getDefs(0, id ?? 0)
  const types = [
    ['', '60%', '80%', '20%', ''],
    ['rotate(-20 0 0)', '64%', '80%', '10%', 'translate(-80 -40) rotate(-20)'],
    ['rotate(-40 0 0)', '64%', '84%', '10%', 'translate(-140 -20) rotate(-90)'],
    ['rotate(20 0.9 0)', '64%', '80%', '10%', 'translate(80 -40) rotate(20)'],
    ['rotate(40 0.9 0)', '64%', '84%', '10%', 'translate(140 -20) rotate(90)'],
    ['', '60%', '80%', '20%', 'translate(0 -20)'],
    ['', '85%', '90%', '5%', 'translate(0 80)'],
  ]

  return `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="200 20 680 388">
      <defs>
	  ${defs}
        <linearGradient id="lgHead${type}" x1="0%" y1="0%" x2="0%" y2="100%" gradientTransform="${types[type][0]}">
          <stop offset="${types[type][1]}" stop-color="#A7A7A7" stop-opacity="1" />
          <stop offset="${types[type][2]}" stop-color="#57C757" stop-opacity="1" />
          <stop offset="100%" stop-color="#9070C7" stop-opacity="1" />
        </linearGradient>
        <linearGradient id="lgBody" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stop-color="#9070C7" stop-opacity="1" />
          <stop offset="${types[type][3]}" stop-color="#878787" stop-opacity="1" />
        </linearGradient>
        <g id="beak">
            
        </g>
        <path id="head${type}" fill="url(#lgHead${type})" d="${heads[type]}" />
		<path id="headshadow${type}" fill="url(#lgHead${type})" d="${headshadows[type]}" />
      </defs>
      <g transform="translate(40,40)" strokeLinejoin="round" strokeLinecap="round" stroke="black" stroke-width="1">
        <use href="#head${type}" />
        <g transform="${faceTransforms[type]}">
		    <g transform="translate(464,160)" />
		        <path class="be0${id}" d="M4 44 Q40 20 76 44 Q80 48 76 60 Q56 108 40 108 Q24 108 4 60 Q0 48 4 44Z M28 48 c4 4 -4 4 0 0Z M52 48 c4 4 -4 4 0 0" />
	            <path fill="url(#mo)" stroke-width="1" d="M8 56 Q40 144 72 56 Q72 56 72 56 Q40 144 8 56 Q8 56 8 56" />
			    <path fill="#ccc" d="M48 24 C92 12 92 76 40 40 C-12 76 -12 12 32 24" />
	            <path stroke="none" opacity=".1" d="M2 48  Q40 156 78 48 Q72 128 40 136 Q8 128 2 48" />
            </g> 
        </g>
		<use href="#feat" transform="translate(440 392)" />
        
		<path class="hr${type}${id}" transform="types[type][4]" stroke-width="0.6" d="${hairf}" />

		<use class="sr${type}${id}" href="#fetr" stroke-width="0.6" transform="translate(356,176) scale(0.8 -1) rotate(66 12 12)" />
		<use class="sl${type}${id}" href="#fetr" stroke-width="0.6" transform="translate(646,208) scale(-0.8 -1) rotate(66 12 12)"/>
      </g>
    </svg>
  `
}

/**
 * Takes in custom size and stroke for circle color, default to primary color as fill,
 * need ...rest for layered styles on top
 */
export function DoveTypes(type: number, id: number, withEyes: boolean): string {
  //const dpi = window.devicePixelRatio || 1;
  const defs = getDefs(0, id ?? 0)
  const types = [
    ['', '60%', '80%', '20%', ''],
    ['rotate(-20 0 0)', '64%', '80%', '10%', 'translate(-80 -40) rotate(-20)'],
    ['rotate(-40 0 0)', '64%', '84%', '10%', 'translate(-140 -20) rotate(-90)'],
    ['rotate(20 0.9 0)', '64%', '80%', '10%', 'translate(80 -40) rotate(20)'],
    ['rotate(40 0.9 0)', '64%', '84%', '10%', 'translate(140 -20) rotate(90)'],
    ['', '60%', '80%', '20%', 'translate(0 -20)'],
    ['', '85%', '90%', '5%', 'translate(0 80)'],
  ]

  return `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="200 20 680 996">
      <defs>
	  ${defs}
        <linearGradient id="bek0${id}" x1="0%" y1="100%" x2="100%" y2="100%">
      <stop offset="0%" class="b20${id}" />
	  <stop offset="50%" class="b10${id}" />
      <stop offset="100%" class="b20${id}" />
    </linearGradient>
	<linearGradient id="nos" x1="0%" y1="100%" x2="100%" y2="100%">
      <stop offset="0%" stop-color="#999" />
      <stop offset="35%" stop-color="#ddd" />
	  <stop offset="65%" stop-color="#ddd" />
      <stop offset="100%" stop-color="#999" />
    </linearGradient>
		<path transform="rotate(-90, 56,62)" fill="#00000019" stroke="none" d="M106 62 c0 30 -25 50 -50 50 c-30 0 -50 -25 -50 -50 Q48 100 106 62Z" />
        
        <path id="footf" d="M58 128 Q54 190 58 218     Q70 225 76 232 Q80 232 70 248 Q64 256 60 248 Q60 244 66 236 Q56 236 56 228    Q58 236 55 240 Q58 250 56 252 Q50 260 44 252 Q42 250 45 240 Q42 238 44 228    Q44 236 34 236 Q40 244 40 248 Q36 256 30 248 Q20 235 24 233 Q30 225 42 218     Q42 218 42 218 Q42 218 42 218 Q42 218 42 218 Q42 218 42 218 Q42 218 42 218    Q46 180 42 128 Q50 116 58 128Z    M64 248 l-6 8 l8 -7 l-2 -1Z   M49 252 l1 8 l1 -8 -2 0Z   M36 248 l6 8 l-8 -7 2 -1Z   M42 218 l0 0 l0 0 l0 0Z" />
        <path id="legf" d="M4 0 Q-12 48 24 84 Q20 108 40 128 Q50 136 60 128 Q80 108 76 84 Q112 48 96 0" />
        <g id="wing">
		<path d="M96 316 Q60 280 76 220  Q116 112 124 12 Q124 0 76 48     Q12 120 12 200   Q12 200 12 200 Q12 200 12 200 Q12 200 12 200    Q16 280 68 316 Q108 340 96 316Z" />
        <path stroke="none" fill="#0002" d="M96 316 Q60 280 80 216 Q136 116 124 12 Q84 188 52 216  Q0 240 68 316 Q108 340 96 316Z" />
	    </g>
		<path id="head${type}" d="${heads[type]}" />
        <path id="tail" d="${tailF}" />
		<path id="fetr" transform="rotate(150 12 12)" d="M-32 50 Q-20 44 -16 50 Q-20 50 -22 56 Q-16 52 -10 56 Q-16 60 -16 62 Q-8 58 -4 62 Q-8 64 -10 69 Q-4 64 2 68 Q-6 72 -6 78" />
	<g id="feat">
	    <use href="#fetr" fill="none" stroke-width="0.5" />
	    <path opacity=".1" transform="rotate(150 12 12)" stroke="none" d="M-32 50 Q-20 44 -16 50 Q-20 50 -22 56 Q-16 52 -10 56 Q-16 60 -16 62 Q-8 58 -4 62 Q-8 64 -10 69 Q-4 64 2 68 Q-6 72 -6 78 Q-6 72 6 64 Q-4 62 -10 69 Q-8 64 0 58 Q-8 55 -16 62 Q-16 60 -7 52 Q-18 50 -22 56 Q-20 50 -12 48 Q-20 41 -32 50Z" />
	</g>
      </defs>
      <g transform="translate(40,40)" strokeLinejoin="round" strokeLinecap="round" stroke="black" stroke-width="1">
        <use href="#tail" class="ta0${id}" />
		<use href="#tail" opacity=".1"/>
        <path class="bo0${id}" d="M608 384 Q730 550 690 620 Q500 900 310 620 Q270 550 392 384" />
        <use href="#feat" transform="translate(568 636) rotate(-30 -12 -40)" />
		
		<path stroke="none" opacity=".1" d="M340 592 Q500 788 660 592 Q680 716 500 760 Q320 716 340 592" />
		<use class="he0${id}" href="#head${type}" />
		<use href="#feat" transform="translate(440 392)" />
		<path stroke="none" fill="#0002" d="${headshadows[type]}" />
        
		<g transform="${faceTransforms[type]}">
		  <path class="hr0${id}" transform="types[type][4]" stroke-width="0.6" d="${hairf}" />

		${
      type == 0
        ? `<use class="sr0${id}" href="#fetr" stroke-width="0.6" transform="translate(356,176) scale(0.8 -1) rotate(66 12 12)" />
		<use class="sl0${id}" href="#fetr" stroke-width="0.6" transform="translate(646,208) scale(-0.8 -1) rotate(66 12 12)"/>
		`
        : null
    }
		  ${
        withEyes
          ? `
		      <g transform="translate(456,68) scale(-1 1)">
                <path class="sl0${id}" d="M-28 28 Q40 4 84 28 Q88 32 98 48 Q116 56 92 76 Q108 100 80 104 Q64 112 40 112 Q24 112 12 116" />
                <path fill="none" stroke-width="1.2" d="M98 48 Q60 38 36 38 Q0 36 -24 56 M80 104 Q64 104 36 102 Q0 96 -16 84" />
	            <path id="glbwL" class="el0${id}" d="M-24 68 Q0 50 28 48 Q88 48 104 64 Q104 84 84 96 Q32 96 28 92 Q4 88 -24 68Z" />
	            <clipPath id="cpiwL">
                  <use href="#glbwL" />
                </clipPath>
	            <g clip-path="url(#cpiwL)">
	              <g>
	                <path fill="#111" stroke="none" d="M46 40 c24 0 32 12 30 28 c0 20 -16 32 -32 32 c-20 0 -32 -16 -32 -28 c0 -12 16 -32 34 -32Z" />
	                <path fill="#fff" stroke="none" d="M52 56 q-8 0 -6 6 q0 8 6 6 q8 0 6 -6 q0 -8 -6 -6 M40 76 q-6 0 -4 4 q0 6 4 4 q6 0 4 -4 q0 -6 -4 -4 " />
                  </g>
	            </g>
	            <path class="sl0${id}" stroke-width="1" clip-path="url(#cpiwL)" d="${lash21}" />
                <path class="sh" d="M96 92 Q100 100 80 104 Q64 112 40 112 Q24 112 0 120 Q-24 80 -24 60 Q56 96 104 64 Q104 80 96 92Z" />
		    </g>
			<g transform="translate(544,68)">
                <path class="sr0${id}" d="M-28 32 Q40 0 84 28 Q88 32 98 48 Q120 56 92 76 Q108 100 80 104 Q64 112 40 112 Q24 112 12 116" />
                <path fill="none" stroke-width="1.2" d="M98 48 Q80 36 36 36 Q0 36 -24 52 M80 104 Q64 104 36 100 Q0 96 -16 80" />
	            <path id="glbwR" class="er0${id}" d="M-24 60 Q0 52 28 44 Q56 40 108 56 Q108 84 84 92 Q32 96 28 92 Q0 88 -24 60Z" />
	            <clipPath id="cpiwR">
                  <use href="#glbwR" />
                </clipPath>
	            <g clip-path="url(#cpiwR)">
	              <g>
	                <path fill="#111" stroke="none" d="M46 40 c28 0 34 16 32 32 c0 12 -17 28 -32 28 c-24 0 -34 -16 -32 -28 c-4 -20 16 -34 32 -32Z" />
	                <path fill="#fff" stroke="none" d="M52 56 q-8 0 -6 6 q0 8 6 6 q8 0 6 -6 q0 -8 -6 -6 M40 76 q-6 0 -4 4 q0 6 4 4 q6 0 4 -4 q0 -6 -4 -4 " />
                  </g>
	            </g>
	            <path class="sr0${id}" stroke-width="1" clip-path="url(#cpiwR)" d="${lash21}" />
                <path class="sh"  d="M96 92 Q100 100 80 104 Q64 112 40 112 Q24 112 0 120 Q-24 80 -24 60 Q56 96 108 56 Q108 80 96 92Z" />
            </g>`
          : null
      }
          <g transform="translate(464,160) scale(0.9)">
            <path class="be0${id}" d="M4 44 Q40 20 76 44 Q80 48 76 60 Q56 108 40 108 Q24 108 4 60 Q0 48 4 44Z M28 48 c4 4 -4 4 0 0Z M52 48 c4 4 -4 4 0 0" />
	        <path fill="url(#mo)" stroke-width="1" d="M8 56 Q40 144 72 56 Q72 56 72 56 Q40 144 8 56 Q8 56 8 56" />
			<path fill="#ccc" d="M48 24 C92 12 92 76 40 40 C-12 76 -12 12 32 24" />
			<path class="sh" d="M48 24 C92 12 92 78 40 42 C-12 78 -12 12 32 24 C-8 12 -8 76 40 38 C88 76 88 12 48 24" />
	        <path stroke="none" opacity=".1" d="${beakshadows[type]}" />
        </g>
        </g>
        <use href="#wing" class="wr0${id}" transform="translate(268,372)" />
        <use href="#wing" class="wl0${id}" transform="translate(732,372) scale(-1 1)" />
        <g transform="translate(388,700)">
          <use href="#footf" fill="#fbb" />
		  <use href="#footf" opacity="0.1" />
          <use href="#legf" class="lr0${id}" />
		  <use href="#legf" opacity="0.1" />
        </g>
        <g transform="translate(512,700)">
          <use href="#footf" fill="#fbb" />
		  <use href="#footf" opacity="0.1" />
          <use href="#legf" class="ll0${id}" />
		  <use href="#legf" opacity="0.1" />
        </g>
      </g>
    </svg>
  `
}

function DoveFTypes(id?: number): string {
  const defs = getDefs(0, id ?? 0)

  return `
    <svg viewBox="-12 -20 1024 1024" xmlns="http://www.w3.org/2000/svg"><defs>
        ${defs}
		<linearGradient id="bek0${id}" x1="0%" y1="100%" x2="100%" y2="100%">
      <stop offset="0%" class="b20${id}" />
	  <stop offset="50%" class="b10${id}" />
      <stop offset="100%" class="b20${id}" />
    </linearGradient>
	<linearGradient id="nos" x1="0%" y1="100%" x2="100%" y2="100%">
      <stop offset="0%" stop-color="#999" />
      <stop offset="35%" stop-color="#ddd" />
	  <stop offset="65%" stop-color="#ddd" />
      <stop offset="100%" stop-color="#999" />
    </linearGradient>
		<g id="eyef">
	    <path d="${globe}" />
		<path id="irisRight" fill="#111" stroke="none" d="M56 30 c20 0 34 17 34 34 c0 20 -17 34 -34 34 c-20 0 -34 -17 -34 -34 c0 -20 17 -34 34 -34" />
		<path id="mirrorRight" fill="#fff" stroke="none" d="M60 50 c4 0 6 3 6 6 c0 4 -3 6 -6 6 c-4 0 -6 -3 -6 -6 c0 -4 3 -6 6 -6 M50 72 c2.8 0 4 2 4 4 c0 2.8 -2 4 -4 4 c-2.8 0 -4 -2 -4 -4 c0 -2.8 2 -4 4 -4" />
    </g>
	<g id="lshRf">
	    <path d="${lash1}" />
	    <path fill="#00000019" stroke="none" d="${shadow1R}" />
	</g>
	<g id="lshLf">
	    <path d="${lash1}" />
	    <path fill="#00000019" stroke="none" d="M68 112 C72 112 72 120 60 120 Q60 120 60 120 Q20 220 0 62 Q56 120 106 62 c0 40 -24 52 -50 50 c-32 0 -50 -25 -50 -50 Q8 100 68 112Z" />
	</g>
<g id="wingf">
	    <path d="M96 316 Q60 280 76 220  Q116 112 124 12 Q124 0 76 48 Q12 120 12 200 Q12 200 12 200 Q12 200 12 200 Q12 200 12 200 Q16 280 68 316 Q108 340 96 316Z" />
		<path stroke="none" fill="#00000019" d="M96 316 Q60 280 80 216 Q136 116 124 12 Q84 188 52 216  Q0 240 68 316 Q108 340 96 316Z" />
	</g>
	<path id="fotRf" d="M58 128 Q54 190 58 218 Q70 225 72 233 Q80 235 82 245 Q80 250 76 250 Q68 245 67 240 Q56 236 56 228 Q58 236 55 245 Q58 250 54 260 Q50 265 46 260 Q42 250 45 245 Q42 238 44 228 Q44 236 33 240 Q32 245 24 250 Q20 250 18 245 Q20 235 28 233 Q30 225 42 218 Q42 218 42 218 Q42 218 42 218 Q42 218 42 218 Q42 218 42 218 Q42 218 42 218 Q46 180 42 128 Q50 116 58 128Z M78 245 l6 8 -8 -7 l2 -1Z M49 258 l2 0 -1 10 -1 -10Z M22 245 l2 1 -8 7 6 -8Z M42 218 l0 0 l0 0 l0 0Z" />   
	<path id="legRf" d="M4 0 Q-12 48 24 84 Q20 108 40 128 Q50 136 60 128 Q80 108 76 84 Q112 48 96 0" />
	<path id="tailf" d="M420 700 Q420 780 440 860 Q460 868 480 860 Q500 872 520 860 Q540 868 560 860 Q580 780 580 700Z M480 860 Q492 752 480 700 M520 700 Q508 752 520 860" />
        
	<path id="fetr" transform="rotate(150 12 12)" d="M-32 50 Q-20 44 -16 50 Q-20 50 -22 56 Q-16 52 -10 56 Q-16 60 -16 62 Q-8 58 -4 62 Q-8 64 -10 69 Q-4 64 2 68 Q-6 72 -6 78" />
	<g id="feat">
	    <use href="#fetr" fill="none" stroke-width="0.5" />
	    <path opacity=".1" transform="rotate(150 12 12)" stroke="none" d="M-32 50 Q-20 44 -16 50 Q-20 50 -22 56 Q-16 52 -10 56 Q-16 60 -16 62 Q-8 58 -4 62 Q-8 64 -10 69 Q-4 64 2 68 Q-6 72 -6 78 Q-6 72 6 64 Q-4 62 -10 69 Q-8 64 0 58 Q-8 55 -16 62 Q-16 60 -7 52 Q-18 50 -22 56 Q-20 50 -12 48 Q-20 41 -32 50Z" />
	</g>
  </defs>
    <g id="dovef${id}" stroke-linejoin="round" stroke-linecap="round" stroke="black" stroke-width="1.2">
        <path class="ta0${id}" d="M420 700 Q420 780 440 860 Q460 868 480 860 Q500 872 520 860 Q540 868 560 860 Q580 780 580 700Z M480 860 Q492 752 480 700 M520 700 Q508 752 520 860" />
        <use href="#tailf" class="ta0${id}" />
		<use href="#tailf" opacity=".1" />
		<path class="bo0${id}" d="M608 387 Q730 550 690 620 Q500 900 310 620 Q270 550 392 387" />    
		<use href="#feat" transform="translate(568 636) rotate(-30 -12 -40)" />
		<path stroke="none" opacity=".1" d="M340 592 Q500 788 660 592 Q680 716 500 760 Q320 716 340 592" />
		<path class="he0${id}" d="M392 388 Q400 300 412 140 Q420 40 500 40 Q580 40 588 140 Q600 300 608 388" />
	    <use href="#feat" transform="translate(440 392)" />
        
		<path class="hr0${id}" stroke-width="0.6" d="${hairf}" />

		<use class="sr0${id}" href="#fetr" stroke-width="0.6" transform="translate(356,176) scale(0.8 -1) rotate(66 12 12)" />
		<use class="sl0${id}" href="#fetr" stroke-width="0.6" transform="translate(646,208) scale(-0.8 -1) rotate(66 12 12)"/>
        <g transform="translate(468,68) scale(-1 1)">
		    <use href="#eyef" class="er0${id}" />
		    <use href="#lshRf" class="sr0${id}" />
		</g>
		<g transform="translate(532,68)">
            <use href="#eyef" class="el0${id}" />
		    <use href="#lshLf" class="sl0${id}" />
		</g>
		<g transform="translate(464,166) scale(0.9)">
	        <path class="be0${id}" d="M4 44 Q40 20 76 44 Q80 48 76 60 Q56 108 40 108 Q24 108 4 60 Q0 48 4 44Z M28 48 c4 4 -4 4 0 0 M52 48 c4 4 -4 4 0 0" />
	        <path fill="#ccc" d="M48 24 C92 12 92 76 40 40 C-12 76 -12 12 32 24" />
	        <path stroke="none" opacity=".1" d="M2 48  Q40 156 78 48 Q72 128 40 136 Q8 128 2 48" />
	        <path fill="#ffe" stroke-width="0.6" d="M12 56 Q40 84 68 56 Q74 52 72 56 Q40 140 8 56 Q6 52 12 56 M72 54 Q40 112 8 54 Q40 112 72 54Z M14 60 Q14 64 14 70 M18 63 Q20 72 22 82 M30 67 Q30 72 28 92 M34 70 Q38 72 42 92 M46 70 Q52 72 52 92 M62 63 Q60 72 58 82 M64 60 Q66 64 66 70" />   
	        <path fill="#fcc" stroke-width="0.6" d="M12 56 Q12 60 16 62 Q20 70 30 71 Q40 80 50 71 Q60 70 64 62 Q68 60 68 56 Q40 80 12 56Z M8 56 Q15 66 19 76 Q29 86 30 91 Q40 88 50 91 Q51 86 61 76 Q66 64 72 56 Q40 140 8 56Z" />
	    </g>
		<g transform="translate(268,372)">
	        <use href="#wingf" class="wr0${id}" />
			<use href="#feat" transform="translate(28,240) rotate(-10 -12 -40)" /> 
	    </g>
	    <g transform="translate(732,372) scale(-1 1)">
	      <use href="#wingf" class="wl0${id}" />
          <use href="#feat" transform="translate(60,160)" />
	    </g>
		<g transform="translate(388,700)">
		    <use href="#fotRf" fill="#fbb" />
			<use href="#fotRf" opacity=".1" />
			<use href="#legRf" class="lr0${id}" />
			<use href="#feat" transform="translate(20,76) rotate(-20 -12 -40)" />
			<use href="#legRf" opacity=".1" />
		</g>
		<g transform="translate(512,700)">
            <use href="#fotRf" fill="#fbb" />
			<use href="#fotRf" opacity=".1" />
            <use href="#legRf" class="ll0${id}" />
			<use href="#legRf" opacity=".1" />   
		</g>	
    </g>
    </svg>
  `
}

function DoveSTypes(id?: number): string {
  const defs = getDefs(1, id ?? 0)

  return `
    <svg viewBox="-24 -68 1120 1120" xmlns="http://www.w3.org/2000/svg">
      <defs>
        ${defs}
		<linearGradient id="bek1${id}" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" class="b11${id}" />
	    <stop offset="30%" class="b11${id}" />
        <stop offset="100%" class="b21${id}" />
    </linearGradient>
	<linearGradient id="noss" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" stop-color="#ddd" stop-opacity="1" />
	    <stop offset="30%" stop-color="#ddd" stop-opacity="1" />
        <stop offset="100%" stop-color="#999" stop-opacity="1" />
    </linearGradient>
		<g id="eyes">
	    <path d="M56 14 c30 0 50 25 50 50 c0 30 -25 50 -50 50 c-30 0 -50 -25 -50 -50 c0 -30 25 -50 50 -50" />
		<path fill="#111" stroke="none" d="M56 30 c20 0 34 17 34 34 c0 20 -17 34 -34 34 c-20 0 -34 -17 -34 -34 c0 -20 17 -34 34 -34" />
		<path fill="#fff" stroke="none" d="M60 50 c4 0 6 3 6 6 c0 4 -3 6 -6 6 c-4 0 -6 -3 -6 -6 c0 -4 3 -6 6 -6 M50 72 c2.8 0 4 2 4 4 c0 2.8 -2 4 -4 4 c-2.8 0 -4 -2 -4 -4 c0 -2.8 2 -4 4 -4" />
    </g>
	<g id="lshRs">
	    <path d="${lash1}" />
	    <path fill="#0002" stroke="none" d="${shadow1R}" />
	</g>
	<g id="lshLs">
	    <path d="M64 8 C76 8 72 16 72 16 Q8 24 8 64 Q8 100 68 112 C72 112 72 120 64 120 Q0 104 0 64 Q0 20 64 8Z M-12 48 Q-12 12 36 0 Q56 -4 64 8 Q0 20 0 64 Q0 104 64 120 Q64 120 64 120 Q16 128 -4 96" />
	    <path fill="#0002" stroke="none" d="M68 112 C72 112 72 120 60 120 Q16 128 -4 96Q0 80 6 62 Q56 120 106 62 c0 30 -25 50 -50 50 c-30 0 -50 -25 -50 -50 Q8 100 68 112Z" />
	</g>
	<path id="foots" d="M58 120 Q54 190 58 214 Q68 214 78 218 Q88 216 98 220 Q98 220 98 220 Q88 216 78 218 Q68 214 58 214 Q68 214 78 218 Q88 216 98 220 Q100 223 98 227 Q83 230 76 228 Q65 230 60 228 Q72 234 72 238 Q82 245 80 248 Q76 256 65 248 Q56 240 56 240 Q46 236 42 229 Q28 232 25 231 Q16 234 10 233 Q08 230 10 227 Q14 222 25 222 Q30 218 42 216 Q46 190 42 120Z M98 220 l0 0 l0 0 l0 0Z M95 222 l10 1 l-10 1 l0 -2Z M76 247 l6 8 l-8 -7 l2 -1Z M13 228 l-10 4 l10 -2 0 -2Z" />
	<path id="legs" d="M-8 0 Q-20 48 24 84 Q20 108 40 128 Q50 136 60 128 Q80 108 76 84 Q120 48 108 0" />
	<path id="tails" d="M340 592 Q110 770 100 808  Q100 820 124 812 Q124 820 140 816 Q140 828 156 820 Q320 700 380 708 M124 812 Q260 712 300 656 M340 660 Q240 736 140 816"/>
	<g id="fetrs" transform="rotate(0 12 12)">
	    <path fill="none" stroke-width="0.5" transform="rotate(150 12 12)"  d="M-32 50 Q-20 44 -16 50 Q-20 50 -22 56 Q-16 52 -10 56 Q-16 60 -16 62 Q-8 58 -4 62 Q-8 64 -10 69 Q-4 64 2 68 Q-6 72 -6 78" />
	    <path opacity=".1" transform="rotate(150 12 12)" stroke="none" d="M-32 50 Q-20 44 -16 50 Q-20 50 -22 56 Q-16 52 -10 56 Q-16 60 -16 62 Q-8 58 -4 62 Q-8 64 -10 69 Q-4 64 2 68 Q-6 72 -6 78 Q-6 72 6 64 Q-4 62 -10 69 Q-8 64 0 58 Q-8 55 -16 62 Q-16 60 -7 52 Q-18 50 -22 56 Q-20 50 -12 48 Q-20 41 -32 50Z" />
	</g>
	</defs>
	<g stroke-linejoin="round" stroke="black" stroke-linecap="round" stroke-width="1.2">
	    <use href="#tails" class="ta1${id}" />
		<use href="#tails" opacity=".1" />
	    <g transform="translate(584,700) scale(0.9)">
            <use href="#foots" fill="#fbb" />
			<use href="#foots" opacity=".1" />
            <use href="#legs" class="ll1${id}" />
			<use href="#legs" opacity=".1" />
		</g>
        <path class="bo1${id}"  d="M628 386 Q440 420 340 592 Q288 660 380 708 Q824 900 848 386"/>
		<path stroke="none" opacity=".1" d="M340 592 Q288 660 380 708 Q824 900 848 387 Q840 840 340 592Z" />
		<g transform="translate(824,68) scale(0.95)">
            <use href="#eyes" class="el1${id}" />
		    <use href="#lshLs" class="sl1${id}" />
		</g>
		<path class="he1${id}" d="M848 388 Q852 272 844 148 Q836 32 744 40 Q668 48 660 140 Q644 280 628 388"/>
		<use href="#fetrs" transform="translate(800,400)" />
		<use href="#fetrs" transform="translate(684,236)" />
		<path class="hr1${id}" stroke-width="0.6" d="M732 48 q4 -12 16 -20 q-4 8 0 12 q4 -14 16 -16 q-6 4 -4 14 q8 -18 20 -18 q-8 12 -4 18 q4 -18 20 -22 q-8 16 -4 32" />
		<path stroke="none" opacity=".1" d="M660 140 Q644 280 628 388 Q636 388 644 388 Q660 280 660 140Z" />
		<g transform="translate(828,168)" stroke-width="1">    
	        <path fill="#ffe" stroke-width="0.6" d="M92 76 Q56 72 0 56 Q-4 54 0 52 Q36 60 92 44 Q 96 56 92 76Z M-2 54 Q40 64 94 60 Q40 64 -2 54Z M12 54 Q16 60 16 60 M40 54 Q38 60 36 66 M56 52 Q60 56 63 72 M84 49 Q82 56 77 74" />  
	        <path fill="#fcc" stroke-width="0.6" d="M0 52 Q12 58 36 56 Q60 58 80 49 Q98 48 100 40 Q36 60  0 52 M0 56 Q16 58 40 64 Q56 64 76 72 Q98 72 100 80 Q56 72 0 56" />
	        <path class="be0${id}" d="M8 32 Q-52 48 -4 72 Q28 88 100 80 Q56 72 0 56 Q-4 54 0 52 Q36 60 100 40 Q36 28 8 32Z M27 36 c4 4 4 -4 0 0Z M24 32 c4 4 4 -4 0 0Z" />
	        <path fill="#ccc"  d="M-8 28 C4 20 24 20 28 32 C24 48 -56 60 -8 28" />
		    <path class="hr1${id}" transform="translate(-828,-168)" stroke-width="0.6" d="M794 218 q14 -8 18 0 q-4 0 -6 6 q6 -4 12 0 q-6 4 -6 6 q8 -6 12 0 q-4 2 -6 7 q6 -5 12 -1 q-8 4 -8 10" />
	        <path opacity=".1" stroke="none" d="M0 56 Q-52 32 0 132 Q12 132 20 80 Q28 84 100 80 Q60 72 0 56Z" />
	    </g>
		<g transform="translate(744,68) scale(1.03)">
		    <use href="#eyes" class="er1${id}" />
		    <use href="#lshRs" class="sr1${id}" />
		</g>
		<g transform="translate(528 700)">
		    <use href="#foots" fill="#fbb" />
			<use href="#foots" opacity=".1" />
		    <use href="#legs" class="lr1${id}" />
			<use href="#fetrs" transform="translate(48,76) rotate(-20 -12 -40)" />
			<use href="#legs" opacity=".1" />
		</g>
		<g transform="translate(224,564) rotate(-20)" stroke-width="1">
	        <path class="ta1${id}" d="M325 60 Q110 64 80 88 Q60 84 40 96 Q16 96 0 110 Q-20 106 -40 120 Q-80 168 280 224   M80 88 Q20 140 200 152 M40 96 Q0 160 232 168 M0 110 Q-28 160 240 188" />  
	        <path class="wr1${id}" d="M440 -28 Q260 -60 116 52 Q80 84 132 88 Q120 116 156 116 Q152 144 188 144 Q190 176 212 182 Q205 200 224 220 Q320 280 460 220 Q600 160 528 40 Q500 -16 440 -28 Z" />
            <use href="#fetrs" transform="translate(380,200) rotate(-10 -12 -40)" />
	        <use href="#fetrs" transform="translate(360,68) rotate(10 -12 -40)" />
	        <use href="#fetrs" transform="translate(220,120)" />
		    <path fill="#0008" stroke="none"  d="M224 220 Q224 200 236 188 Q230 160 260 148 Q240 120 276 92 Q260 60 284 40 Q256 16 292 -29 L240 -16 Q220 24 240 40 Q224 64 244 88 Q220 124 232 144 Q216 156 212 182 Q205 200 224 220 Z" />
            <path fill="#0008" stroke="none" d="M212 182 Q200 164 212 140 Q200 124 212 96 Q200 80 208 52 Q180 36 192 4 L136 36 Q132 36 168 52 Q156 84 188 100 Q172 124 188 144 Q190 176 212 182 Z" />
	        <path opacity=".1" stroke="none" d="M224 220 Q320 280 460 220 Q600 160 528 40 Q628 196 224 220Z" />
	    </g>
		</g>
    </svg>
  `
}

function DoveMTypes(id?: number): string {
  const defs = getDefs(2, id ?? 0)

  return `
    <svg viewBox="-60 -60 1080 1080" xmlns="http://www.w3.org/2000/svg">
      <defs>
        ${defs}
		<linearGradient id="bek1${id}" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" class="b12${id}" />
	    <stop offset="30%" class="b12${id}" />
        <stop offset="100%" class="b22${id}" />
    </linearGradient>
	<linearGradient id="noss" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" stop-color="#ddd" stop-opacity="1" />
	    <stop offset="30%" stop-color="#ddd" stop-opacity="1" />
        <stop offset="100%" stop-color="#999" stop-opacity="1" />
    </linearGradient>
		<g id="eyem">
	    <path d="M56 14 c30 0 50 25 50 50 c0 30 -25 50 -50 50 c-30 0 -50 -25 -50 -50 c0 -30 25 -50 50 -50" />
		<path fill="#111" stroke="none" d="M56 30 c20 0 34 17 34 34 c0 20 -17 34 -34 34 c-20 0 -34 -17 -34 -34 c0 -20 17 -34 34 -34" />
		<path fill="#fff" stroke="none" d="M60 50 c4 0 6 3 6 6 c0 4 -3 6 -6 6 c-4 0 -6 -3 -6 -6 c0 -4 3 -6 6 -6 M50 72 c2.8 0 4 2 4 4 c0 2.8 -2 4 -4 4 c-2.8 0 -4 -2 -4 -4 c0 -2.8 2 -4 4 -4" />
    </g>
	<g id="lshRm">
	    <path d="M64 8 C76 8 72 16 72 16 Q8 24 8 64 Q8 100 68 112 C72 112 72 120 64 120 Q0 104 0 64 Q0 20 64 8Z M-12 48 Q-12 12 36 0 Q56 -4 64 8 Q0 20 0 64 Q0 104 64 120 Q64 120 64 120 Q16 128 -4 96" />
	    <path fill="#00000019" stroke="none" d="M68 112 C72 112 72 120 60 120 Q60 120 60 120 Q20 220 0 62 Q56 120 106 62 c0 40 -24 52 -50 50 c-32 0 -50 -25 -50 -50 Q8 100 68 112Z" />
	</g>
	<g id="lshLm">
	    <path d="M64 8 C76 8 72 16 72 16 Q8 24 8 64 Q8 100 68 112 C72 112 72 120 64 120 Q0 104 0 64 Q0 20 64 8Z M-12 48 Q-12 12 36 0 Q56 -4 64 8 Q0 20 0 64 Q0 104 64 120 Q64 120 64 120 Q16 128 -4 96" />
	    <path fill="#00000019" stroke="none" d="M68 112 C72 112 72 120 60 120 Q16 128 -4 96Q0 80 6 62 Q56 120 106 62 c0 30 -25 50 -50 50 c-30 0 -50 -25 -50 -50 Q8 100 68 112Z" />
	</g>
	
	<path id="footm" d="M58 120 Q54 190 58 216 Q68 215 78 218 Q88 215 98 218 Q104 223 98 227 Q88 230 78 227 Q69 230 62 227 Q72 230 80 236 Q96 242 95 250 Q87 260 80 250 Q73 248 69 242 Q59 240 54 234 Q54 236 52 244 Q56 248 52 260 Q48 264 42 260 Q38 244 40 240 Q36 230 40 222 Q28 218 27 214 Q18 210 18 204 Q20 196 28 200 Q32 202 34 206 Q40 208 44 212 Q46 180 42 120Z M96 221 l10 1 l-10 1 l0 -2Z M90 248 l6 8 l-8 -7 l2 -1Z M46 256 l-1 10 l3 -10 -2 0Z M24 202 l-8 -5 l6 6 2 -1Z" />
	<path id="legm" d="M-4 0 Q-16 48 24 84 Q20 108 40 124 Q50 132 60 124 Q80 108 76 84 Q116 48 104 0" />
    <path id="tailm"   d="M316 588 Q140 770 120 804 Q108 828 144 812  Q140 832 160 820 Q156 844 176 828 Q292 740 352 712 M144 812 Q260 720 300 636 M340 680 Q240 752 160 820"/>
    
	
    <g id="fetrm" transform="rotate(0 12 12)">
	  <path fill="none" stroke-width="0.5" transform="rotate(150 12 12)"  d="M-32 50 Q-20 44 -16 50 Q-20 50 -22 56 Q-16 52 -10 56 Q-16 60 -16 62 Q-8 58 -4 62 Q-8 64 -10 69 Q-4 64 2 68 Q-6 72 -6 78" />
	  <path opacity=".1" transform="rotate(150 12 12)" stroke="none" d="M-32 50 Q-20 44 -16 50 Q-20 50 -22 56 Q-16 52 -10 56 Q-16 60 -16 62 Q-8 58 -4 62 Q-8 64 -10 69 Q-4 64 2 68 Q-6 72 -6 78 Q-6 72 6 64 Q-4 62 -10 69 Q-8 64 0 58 Q-8 55 -16 62 Q-16 60 -7 52 Q-18 50 -22 56 Q-20 50 -12 48 Q-20 41 -32 50Z" />
	</g>
  </defs>
  <g stroke-linejoin="round" stroke-linecap="round" stroke="black" stroke-width="1.2">
    <use href="#tailm" class="ta2${id}" />
	<use href="#tailm" opacity=".1" />
    <g transform="translate(540,700) scale(0.93)">
         <use href="#footm" fill="#fbb"/>
		 <use href="#footm" opacity=".1"/>
         <use href="#legm" class="ll2${id}" />
		 <use href="#legm" opacity=".1" />
    </g>
    <path class="bo2${id}" d="M524 387 Q372 420 316 588 Q276 680 352 712 Q740 900 740 387"/>
	<path stroke="none" opacity=".1" d="M316 588 Q248 668 352 712 Q748 900 740 387 Q760 840 316 588Z" />
    <path class="he2${id}" d="M740 388 Q736 260 724 148 Q720 36 632 40 Q552 44 548 156 Q540 260 524 388"/>
    <use href="#fetrm" transform="translate(644 400)"   />
	<use href="#fetrm" transform="translate(560 236)" />
	<path class="hr2${id}" stroke-width="0.6"  d="M608 48 q6 -14 17 -20 q-4 10 0 10 q4 -14 18 -16 q-6 4 -4 14 q8 -18 22 -18 q-8 10 -4 18 q4 -18 22 -22 q-12 16 -4 40" />
	<path stroke="none" opacity=".1" d="M524 388 Q540 260 548 156 Q552 240 544 388Z" />	

	<g transform="translate(704,68)">
            <use href="#eyem" class="el2${id}" />
		    <use href="#lshLm" class="sl2${id}" />
		</g>

	<g transform="translate(688,168)" stroke-width="1">
        <path fill="#ffe" stroke-width="0.6" d="M88 78 Q56 72 0 56 Q-4 54 0 52 Q36 60 88 42 Q 93 56 88 78Z M-2 54 Q40 64 90 60 Q40 64 -2 54Z M12 54 Q16 60 16 60 M36 56 Q34 60 32 64 M48 54 Q51 56 56 70 M76 49 Q74 56 66 74" />  
	 
	    <path fill="#fcc" stroke-width="0.6" d="M0 52 Q10 58 34 56 Q56 58 76 49 Q92 48 92 40 Q36 60  0 52 M0 56 Q16 58 36 63 Q52 64 72 72 Q92 72 92 80 Q56 72 0 56" />
		
		<path class="be0${id}" d="M8 32 Q-40 48 0 72 Q24 88 92 80 Q56 72 0 56 Q-4 54 0 52 Q40 60 92 40 Q32 24 8 32Z M24 36 c4 4 4 -4 0 0Z M24 30 c4 4 4 -4 0 0Z" />
	    <path fill="#ccc"  d="M12 22 C20 20 36 28 26 32 C12 52 -52 56 4 26" />
		<path class="hr2${id}" transform="translate(-688,-168)" stroke-width="0.6" d="M660 218 q14 -8 18 0 q-4 0 -6 6 q6 -4 12 0 q-6 4 -6 6 q8 -6 12 0 q-4 2 -6 7 q6 -5 12 -1 q-8 4 -8 10" />

		<path opacity=".1" stroke="none" d="M0 54 Q-48 40 12 132 Q28 98 28 80 Q28 84 92 80 Q60 72 0 54Z" />
	</g>
	<g transform="translate(608,68) scale(1.05)">
            <use href="#eyem" class="er2${id}" />
		    <use href="#lshRm" class="sr2${id}" />
		</g>
    <g transform="translate(436,700) scale(1)">
      <use href="#footm" fill="#fbb"/>
	  <use href="#footm" opacity=".1"/>
      <use href="#legm" class="lr2${id}" />
	  <use href="#fetrm" transform="translate(36,76) rotate(-20 -12 -40)" />
	  <use href="#legm" opacity=".1" />
    </g>
	<g transform=" translate(180,574) rotate(-25) scale(0.86 .98)" stroke-width="1">
	        <path class="ta2${id}" transform="scale(0.9 1)" d="M325 60 Q110 64 80 88 Q60 84 40 96 Q16 96 0 110 Q-20 106 -40 120 Q-80 168 280 224 M80 88 Q20 140 200 152 M40 96 Q0 160 232 168 M0 110 Q-28 160 240 188" />  
	        <path class="wr2${id}" d="M440 -28 Q260 -60 116 52 Q80 84 132 88 Q120 116 156 116 Q152 144 188 144 Q190 176 212 182 Q205 200 224 220 Q320 280 460 220 Q600 160 528 40 Q500 -16 440 -28 Z" />
            <use href="#fetrm" transform="translate(380,200) rotate(-10 -12 -40)" />
	        <use href="#fetrm" transform="translate(360,68) rotate(10 -12 -40)" />
	        <use href="#fetrm" transform="translate(220,120)" />
		    <path fill="#0008" stroke="none"  d="M224 220 Q224 200 236 188 Q230 160 260 148 Q240 120 276 92 Q260 60 284 40 Q256 16 292 -29 L240 -16 Q220 24 240 40 Q224 64 244 88 Q220 124 232 144 Q216 156 212 182 Q205 200 224 220 Z" />
            <path fill="#0008" stroke="none" d="M212 182 Q200 164 212 140 Q200 124 212 96 Q200 80 208 52 Q180 36 192 4 L136 36 Q132 36 168 52 Q156 84 188 100 Q172 124 188 144 Q190 176 212 182 Z" />
	        <path opacity=".1" stroke="none" d="M224 220 Q320 280 460 220 Q600 160 528 40 Q628 196 224 220Z" />
	    </g>
  </g>
    </svg>
  `
}

/**
 * Takes in custom size and stroke for circle color, default to primary color as fill,
 * need ...rest for layered styles on top
 */
function DoveDTypes(type: number): string {
  //const dpi = window.devicePixelRatio || 1;
  const id = 0
  const footFR =
    'M16 136 Q48 180 58 218     Q70 225 72 233 Q80 235 82 245 Q80 250 76 250 Q68 245 67 240 Q56 236 56 228    Q58 236 55 245 Q58 250 54 260 Q50 265 46 260 Q42 250 45 245 Q42 238 44 228    Q44 236 33 240 Q32 245 24 250 Q20 250 18 245 Q20 235 28 233 Q30 225 42 218     Q42 218 42 218 Q42 218 42 218 Q42 218 42 218 Q42 218 42 218 Q42 218 42 218    Q36 180 0 136 Q0 112 16 136Z    M78 245 l6 8 l-8 -7 l2 -1Z   M49 258 l1 10 l1 -10 -2 0Z   M22 245 l-6 8 l8 -7 -2 -1Z   M42 218 l0 0 l0 0 l0 0Z'
  const footFL =
    'M96 136 Q68 180 58 218     Q70 225 72 233 Q80 235 82 245 Q80 250 76 250 Q68 245 67 240 Q56 236 56 228    Q58 236 55 245 Q58 250 54 260 Q50 265 46 260 Q42 250 45 245 Q42 238 44 228    Q44 236 33 240 Q32 245 24 250 Q20 250 18 245 Q20 235 28 233 Q30 225 42 218     Q42 218 42 218 Q42 218 42 218 Q42 218 42 218 Q42 218 42 218 Q42 218 42 218    Q56 180 80 136 Q88 112 96 136Z    M78 245 l6 8 l-8 -7 l2 -1Z   M49 258 l1 10 l1 -10 -2 0Z   M22 245 l-6 8 l8 -7 -2 -1Z   M42 218 l0 0 l0 0 l0 0Z'
  const bodyFR = 'M608 387 Q700 550 636 620 Q380 900 272 620 Q240 550 392 387'
  const bodyFL = 'M608 387 Q756 550 728 620 Q620 900 360 620 Q300 550 392 387'
  const tailFR =
    'M480 700 Q496 780 440 860    Q460 868 480 860   Q500 872 520 860  Q540 868 560 860  Q640 780 640 700Z       M480 860 Q552 752 560 700   M600 700 Q568 752 520 860'
  const tailFL =
    'M360 700 Q360 780 440 860    Q460 868 480 860   Q500 872 520 860  Q540 868 560 860  Q500 780 520 700Z       M480 860 Q432 752 400 700   M440 700 Q448 752 520 860'

  const wings = [
    [
      'M120 52 Q136 8 100 0 Q40 0 -44 12 Q-136 20 -212 40 Q-320 60 -360 88 Q-440 152 -240 124 Q-160 152 -48 132  Q20 160 80 148',
      'M120 52  Q136 8 100 0 Q40 0 -44 12 Q-136 20 -212 40 Q-320 60 -360 88 Q-200 104 -180 100 Q-180 100 -180 100 Q-180 100 -180 100 Q-112 104 0 96 Q96 88 120 48Z',
      'M60 80 Q56 132 48 156  Q24 188 0 160 Q-20 188 -48 160  Q-68 188 -100 160 Q-140 188 -160 160  Q-200 192 -220 152   Q-260 180 -288 148   Q-380 180 -380 120   Q-380 152 -320 80     M-40 80 Q-20 120 0 160 M-88 100 Q-88 40 -48 160 M-140 76 Q-160 20 -100 160 M-200 40 Q-120 80 -160 160    M-160 80 Q-180 126 -220 152 M-220 80 Q-240 96 -288 148',
      '',
    ],
    [
      'M120 52 Q136 8 100 0 Q40 0 -44 76 Q-100 120 -152 200 Q-208 276 -232 360 Q-252 472 -144 320  Q-72 288 -24 212  Q28 204 80 148',
      'M120 52  Q136 8 100 0 Q40 0 -44 76 Q-100 120 -152 200 Q-208 276 -232 360 Q-188 296 -120 248 Q-120 240 -120 248 Q-120 240 -120 248 Q-96 232 8 148 Q96 88 120 48Z',
      'M60 80 Q56 120 56 188  Q48 220 28 208 Q12 252 -4 228  Q-20 284 -36 260 Q-52 312 -76 296  Q-88 340 -120 328   Q-128 380 -168 372   Q-200 460 -224 400   Q-200 232 -100 180     M12 120 Q20 180 28 208 M-8 180 Q-8 196 -4 228 M-28 176 Q-32 228 -36 260 M-80 160 Q-80 180 -76 296    M-140 220 Q-120 246 -120 328 M-160 240 Q-132 296 -168 368',
      '',
    ],
    [
      '',
      'M84 20 Q116 -20 180 80 Q220 136 232 168 Q336 180 400 212 Q488 280 232 324 Q168 340 120 300 Q0 208 12 148 Q20 88 84 20',
      'M60 80 Q56 100 20 168  Q20 320 44 244 Q48 360 76 284  Q96 400 124 320 Q140 420 192 340  Q200 420 260 332   Q252 420 320 320   Q300 440 416 256   Q140 232 240 180     M96 120 Q60 180 44 244 M140 180 Q96 196 76 284 M120 176 Q128 124 124 320 M240 180 Q200 220 192 340    M280 220 Q268 246 260 332 M340 240 Q320 300 320 320',
      '',
    ],
    [
      'M120 48 Q136 8 100 0 Q40 -12 -20 60 Q-100 144 -100 276 Q-100 408 -28 476 Q12 500 -8 376 Q12 320 4 236  Q52 188 60 148',
      'M120 48  Q136 8 100 0 Q40 -12 -20 60 Q-100 144 -100 276 Q-100 408 -28 476 Q-44 380 -16 248 Q-16 248 -16 248 Q-16 248 -16 248 Q4 140 28 120 Q96 88 120 48Z',
      'M60 80 Q56 120 40 168  Q20 220 36 180 Q16 200 20 216  Q32 240 16 252 Q24 280 12 288  Q28 320 8 340   Q32 380 8 400   Q36 480 -8 472   Q-40 232 -40 180     M-40 120 Q-20 180 36 180 M-28 180 Q-12 196 20 216 M-40 176 Q-16 180 16 252 M-40 160 Q-20 180 12 288    M-40 220 Q-12 246 8 340 M-40 240 Q-20 300 8 400',
      '',
    ],
    [
      'M120 48 Q136 8 100 0  Q40 -12 -12 60 Q-80 144 -68 276 Q-48 408 24 452 Q88 496 40 372 Q64 312 20 228  Q60 160 60 148',
      'M120 48  Q140 8 100 0  Q44 -12 -12 60 Q-80 144 -68 276 Q-48 408 24 452 Q-52 380 -48 288 Q-48 288 -48 288 Q-48 288 -48 288 Q-56 140 28 84 Q80 44 120 48Z',
      'M60 80 Q56 120 40 168  Q20 220 36 180 Q0 200 24 204  Q36 220 40 252 Q60 268 52 288  Q68 300 60 332   Q80 360 60 384  Q96 440 60 456 Q-40 232 -40 180 M-40 120 Q-20 180 36 180 M-28 180 Q-12 196 24 204 M-40 176 Q-16 180 40 252 M-40 160 Q-20 180 52 288    M-40 220 Q-12 246 60 332 M-40 240 Q0 300 60 384',
      '',
    ],
    ['M120 48 Q136 8 100 20 Q0 20 -4 80 Q-12 104 40 148', 'M120 48  Q136 8 100 20 Q0 20 -4 80  Q96 28 120 48Z', '', ''],
  ]

  const types = [
    [
      bodyFL,
      footFL,
      tailFR,
      wings[1],
      wings[5],
      '40',
      '688',
      'translate(448 700) rotate(10 48 0)',
      'translate(568 700) rotate(10 48 0)',
    ],
    [bodyF, footF, tailF, wings[3], wings[4], '28', '700', 'translate(388 700)', 'translate(512 700)'],
    [
      bodyFR,
      footFR,
      tailFL,
      wings[2],
      wings[0],
      '40',
      '688',
      'translate(328 700) rotate(-10 48 0)',
      'translate(452 700) rotate(-10 48 0)',
    ],
    [bodyF, footF, tailF, wings[3], wings[3], '28', '700', 'translate(388 700)', 'translate(512 700)'],
    [
      bodyFL,
      footFL,
      tailFR,
      wings[0],
      wings[2],
      '40',
      '688',
      'translate(448 700) rotate(10 48 0)',
      'translate(568 700) rotate(10 48 0)',
    ],
    [bodyF, footF, tailF, wings[4], wings[3], '28', '700', 'translate(388 700)', 'translate(512 700)'],
    [
      bodyFR,
      footFR,
      tailFL,
      wings[5],
      wings[1],
      '40',
      '688',
      'translate(328 700) rotate(-10 48 0)',
      'translate(452 700) rotate(-10 48 0)',
    ],
  ]

  return `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-100 48 1280 960">
      <defs>
        <linearGradient id="lgHeadd" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="60%" stop-color="#A8A8A8" stop-opacity="1" />
          <stop offset="80%" stop-color="#57C757" stop-opacity="1" />
          <stop offset="100%" stop-color="#9070C7" stop-opacity="1" />
        </linearGradient>
        <linearGradient id="lgBodyd" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stop-color="#9070C7" stop-opacity="1" />
          <stop offset="20%" stop-color="#888888" stop-opacity="1" />
        </linearGradient>
		<linearGradient id="bekf" x1="0%" y1="100%" x2="100%" y2="100%">
      <stop offset="0%" class="b2${type}${id}" />
	  <stop offset="50%" class="b1${type}${id}" />
      <stop offset="100%" class="b2${type}${id}" />
    </linearGradient>
	<linearGradient id="nosf" x1="0%" y1="100%" x2="100%" y2="100%">
      <stop offset="0%" stop-color="#999" />
      <stop offset="35%" stop-color="#ddd" />
	  <stop offset="65%" stop-color="#ddd" />
      <stop offset="100%" stop-color="#999" />
    </linearGradient>
        <g id="eyef">
          <path class="er${type}${id}" d="${globe}" />
          <path
            fill="#111"
            stroke="none"
            d="M56 30 c20 0 34 17 34 34 c0 20 -17 34 -34 34 c-20 0 -34 -17 -34 -34 c0 -20 17 -34 34 -34"
          />
          <path
            fill="#fff"
            stroke="none"
            d="M60 48 c4 0 6 3 6 6 c0 4 -3 6 -6 6 c-4 0 -6 -3 -6 -6 c0 -4 3 -6 6 -6 M48 72 c2.8 0 4 2 4 4 c0 2.8 -2 4 -4 4 c-2.8 0 -4 -2 -4 -4 c0 -2.8 2 -4 4 -4"
          />
		  </g>
		  <g id="lashf">
          <path d="${lash1}" />
		  <path fill="#0002" d="${shadow1R}" />
		  </g>
        </g>
    }
        <g id="beakf">
            <path class="be0${id}" d="M4 44 Q40 20 76 44 Q80 48 76 60 Q56 108 40 108 Q24 108 4 60 Q0 48 4 44Z M28 48 c4 4 -4 4 0 0Z M52 48 c4 4 -4 4 0 0" />
	        <path fill="url(#mo)" stroke-width="1" d="M8 56 Q40 144 72 56 Q72 56 72 56 Q40 144 8 56 Q8 56 8 56" />
			<path fill="#ccc" d="M48 24 C92 12 92 76 40 40 C-12 76 -12 12 32 24" />
	        <path stroke="none" opacity=".1" d="M2 48  Q40 156 78 48 Q72 128 40 136 Q8 128 2 48" />
        </g>
		<g id="wingdRight${type}">
		  <path fill="#181818" d="${types[type][3]?.[2]}" />
		  <path fill="#e8e8e8" d="${types[type][3]?.[0]}" />
		  <path fill="#666" d="${types[type][3]?.[1]}" />
		  <path fill="#0002" d="${types[type][3]?.[3]}" />
		</g>
		<g id="wingdLeft${type}">
		  <path fill="#181818" d="${types[type][4]?.[2]}" />
		  <path fill="#e8e8e8" d="${types[type][4]?.[0]}" />
		  <path fill="#666" d="${types[type][4]?.[1]}" />
		  <path fill="#0002" d="${types[type][4]?.[3]}" />
		</g>
        <path id="footd${type}" d="${types[type][1]}" />
        <path id="legd" fill="#888888" d="M4 0 Q-12 48 24 84 Q20 108 40 128 Q50 136 60 128 Q80 108 76 84 Q112 48 96 0" />
        <path id="headd" class="he${type}${id}" d="${heads[0]}" />
        <path id="bodyd${type}" class="bo${type}${id}" d="${types[type][0]}" />
        
		<path id="taild${type}" d="${types[type][2]}" />
		<path id="fetr" transform="rotate(150 12 12)" d="M-32 50 Q-20 44 -16 50 Q-20 50 -22 56 Q-16 52 -10 56 Q-16 60 -16 62 Q-8 58 -4 62 Q-8 64 -10 69 Q-4 64 2 68 Q-6 72 -6 78" />
	<g id="feat">
	    <use href="#fetr" fill="none" stroke-width="0.5" />
	    <path opacity=".1" transform="rotate(150 12 12)" stroke="none" d="M-32 50 Q-20 44 -16 50 Q-20 50 -22 56 Q-16 52 -10 56 Q-16 60 -16 62 Q-8 58 -4 62 Q-8 64 -10 69 Q-4 64 2 68 Q-6 72 -6 78 Q-6 72 6 64 Q-4 62 -10 69 Q-8 64 0 58 Q-8 55 -16 62 Q-16 60 -7 52 Q-18 50 -22 56 Q-20 50 -12 48 Q-20 41 -32 50Z" />
	</g>
      </defs>
      <g transform="translate(40 ${types[type][5]})" strokeLinejoin="round" strokeLinecap="round" stroke="black" stroke-width="1.2">
        <use href="#taild${type}" fill="#181818" />
		<use href="#taild${type}" opacity=".1" />
        <use href="#bodyd${type}" />
        <use href="#headd" />
		<g transform="translate(468,68) scale(-1 1)" />
		    <use href="#eyef" class="er${type}${id}" />
		    <use href="#lashf"  class="sr${type}${id}" />
		</g>
		<g transform="translate(532,68)" />
		    <use href="#eyef" class="el${type}${id}" />
		    <use href="#lashf"  class="sl${type}${id}" />
		</g>
        <use href="#beakf" transform="translate(464,160)" />
		<g transform="translate(388 ${types[type][6]})">
		    <use href="#footd${type}" fill="#fbb" />
			<use href="#footd${type}" opacity=".1" />
		</g>
		<g transform="${types[type][7]}">
			<use href="#legd" class="lr${type}${id}" />
			<use href="#legd" opacity=".1" />
		</g>
		<g transform="translate(512 ${types[type][6]})">
		    <use href="#footd${type}" fill="#fbb" />
			<use href="#footd${type}" opacity=".1" />
		</g>
		<g transform="${types[type][8]}">
			<use href="#legd" class="ll${type}${id}" />
			<use href="#legd" opacity=".1" />
		</g>
		<use href="#wingdRight${type}" transform="translate(268,372)" />
		<use href="#wingdLeft${type}" transform="translate(732,372) scale(-1 1)" />
      </g>
    </svg>
  `
}

/**
 * Takes in custom size and stroke for circle color, default to primary color as fill,
 * need ...rest for layered styles on top
 */
function Loader({ size = '16px', stroke, ...rest }: { size?: string; stroke?: string; [k: string]: any }) {
  return (
    <SpinnedSVG
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      size={size}
      stroke={stroke}
      {...rest}
    >
      <path
        d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.27455 20.9097 6.80375 19.1414 5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SpinnedSVG>
  )
}
