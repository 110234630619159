import React from 'react'
import styled from 'styled-components/macro'

const BodyWrapper = styled.main`
  width: 100%;
  margin-top: 1rem;
  position: relative;
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children, ...rest }: { children: React.ReactNode }) {
  return <BodyWrapper {...rest}>{children}</BodyWrapper>
}
