import React, { useRef } from 'react'

interface CanvasProps {
  height: number
  width: number
  // triggers whenever the typed value changes
  //onChange: (value: string) => void;
}

export default function Canvas({
  data,
  alt,
  width,
  height,
  widthAsMax = true,
}: { data: string; alt?: string; widthAsMax?: boolean } & CanvasProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const url = URL.createObjectURL(new Blob([data], { type: 'image/svg+xml;charset=utf-8' }))
  const img = new Image()
  if (alt) img.alt = alt
  const dpi = window.devicePixelRatio || 1
  width = width * dpi
  height = height * dpi
  img.addEventListener(
    'load',
    () => {
      const canvas = canvasRef.current
      if (canvas) {
        const context = canvas.getContext('2d')
        if (context) {
          canvas.width = width
          canvas.height = height
          context.drawImage(img, 0, 0, width, height)
          const png = canvas.toDataURL('image/png')
          URL.revokeObjectURL(png)
          URL.revokeObjectURL(url)
        }
      }
    },
    { once: true }
  )
  img.src = url
  return (
    <canvas
      id="canvas"
      ref={canvasRef}
      style={{ width: '100%', maxWidth: widthAsMax ? width : '100%', pointerEvents: 'none' }}
    />
  )
}

//document.querySelector<HTMLCanvasElement>("#canvas")!.innerHTML = `<img src="${png}"/>`;style={{ maxWidth: width }}
