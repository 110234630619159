export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister()
      })
      .catch((error) => {
        console.log('serviceWorker', 'unregister', 'Service worker unregister failed', error)
      })
  }
}
