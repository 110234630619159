//import { ReactComponent as LogoDark } from "../../assets/images/treats.png";
import { Moon, Sun } from 'react-feather'
import styled from 'styled-components/macro'

import Logo from '../../assets/svg/logo.svg'
import useTheme from '../../hooks/useTheme'
import { ApplicationModal } from '../../state/application/actions'
import { useToggleModal } from '../../state/application/hooks'
import { useDarkModeManager } from '../../state/user/hooks'
import { TYPE } from '../../theme'
import { AutoColumn } from '../Column'
import Menu from '../Menu'
import { RowBetween, RowFixed } from '../Row'

const HeaderFrame = styled.div`
  display: flex;
  //grid-template-columns: 120px 1fr 120px;
  justify-content: space-between;
  align-items: center;
  //flex-direction: no-wrap;
  width: 100%;
  top: 0;
  height: 50px;
  position: relative;
  padding: 0.5rem;
  z-index: 21;
  position: relative;
  background-color: ${({ theme }) => theme.primary1 + 'ee'};
`

const HeaderControls = styled(RowFixed)`
  //display: flex;
  //flex-direction: row;
  //align-items: center;
  margin-right: 4px;
  gap: 4px;
  justify-self: flex-end;
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  &:not(:first-child) {
    margin-right: 0.5em;
  }

  /* addresses safari's lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 8px;
  }
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  text-decoration: none;
  font-size: 24px;
  :hover {
    cursor: pointer;
  }
`

const DoveIcon = styled.img`
  pointer-events: none;
  justify-self: center;
  margin-left: 0px;
  height: 48px;
  width: 48px;
`

const ToggleMenuItem = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  width: 100%;
  //padding: 0.5rem 0.5rem;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.orange};
  opacity: 0.6;
  :hover {
    opacity: 0.4;
    cursor: pointer;
    text-decoration: none;
  }
  transition: opacity 300ms;
`

export default function Header() {
  const [darkMode, toggleDarkMode] = useDarkModeManager()
  const toggle = useToggleModal(ApplicationModal.MENU)

  const theme = useTheme()

  const logo: string = Logo

  //const [menuId, setMenu] = useState("");

  //const isDesktop = window.matchMedia("(min-width: 501px)").matches;

  //const scrollY = useScrollPosition();

  return (
    <HeaderFrame>
      <RowBetween>
        <Title href=".">
          <DoveIcon src={logo} alt="Trade Dove Logo" />
          <AutoColumn>
            <TYPE.White ml="2px">Trade</TYPE.White>
            <TYPE.White ml="8px" mt="-14px" mb="6px">
              Dove
            </TYPE.White>
          </AutoColumn>
        </Title>
        <HeaderControls>
          <ToggleMenuItem onClick={() => toggleDarkMode()}>
            {darkMode ? <Sun size={32} /> : <Moon size={32} />}
          </ToggleMenuItem>
          <Menu menuId="" />
        </HeaderControls>
      </RowBetween>
    </HeaderFrame>
  )
}
